const initialState = [];

const setBasicEdgeSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_BASIC_EDGE_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default setBasicEdgeSettings;