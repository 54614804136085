import React, { useEffect, useState } from "react";
import "./Widget.css";

import { Form, Input, Button, message, Upload } from "antd";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewNotification,
  setPageNotifications,
} from "../../reduxStore/actions";
import { generateTime } from "../../constants/commonFunction";

function Widget() {
  const dispatch = useDispatch();

  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const [isSavingIdentityProvider, setIsSavingIdentityProvider] =
    useState(false);
  const AuthenticationSettingsData = useSelector(
    (state) => state.setAuthenticationSettings
  );

  const [refactored_base64File, setrefactored_Base64File] = useState("");
  const [providervalue, setProviderValue] = useState("");
  const [namevalue, setNameValue] = useState("");
  const [clientIdvalue, setClientIdValue] = useState("");
  const [providerMetaDatavalue, setProvierMetaDataValue] = useState("");
  const [providerUrlValue, setProviderUrlValue] = useState("");

  useEffect(() => {
    setProviderValue(AuthenticationSettingsData?.properties?.[1]?.value);
    setNameValue(AuthenticationSettingsData?.properties?.[0]?.value);
    setClientIdValue(AuthenticationSettingsData?.properties?.[2]?.value);
    setProvierMetaDataValue(AuthenticationSettingsData?.properties?.[4]?.value);
    setProviderUrlValue(AuthenticationSettingsData?.properties?.[3]?.value);
  }, [AuthenticationSettingsData]);

  const targets = [
    "edgeauthadminapi.registerIdentityProvider",
    "edgeauthadminapi.registerEdgeAdministrator",
  ];

  let payload = [
    {
      sectionName: AuthenticationSettingsData.sectionName,
      parentTypeId: AuthenticationSettingsData.parentTypeId,
      properties: [
        {
          value: {},
          targets: "",
        },
      ],
    },
  ];

  const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  let base64File = "";
  const uploadFile = async (event) => {
    const file = event.target.files[0];
    base64File = await convertBase64(file);
    let refactoredBase64 = base64File.split(";base64,");
    setrefactored_Base64File(refactoredBase64[1]);
  };

  const registerIdentityProvider = (values) => {
    if (refactored_base64File == "") {
      values.certificate = AuthenticationSettingsData?.properties?.[5]?.value;
    } else {
      values.certificate = refactored_base64File;
    }

    values.Provider = providervalue;
    values.Name = namevalue;
    values.ClientId = clientIdvalue;
    values.ProviderMetadata = providerMetaDatavalue;
    values.ProviderURL = providerUrlValue;
    payload[0].properties[0].value = values;
    payload[0].properties[0].targets = targets[0];
    console.log(values);
    calltheApi(payload, 0);
  };


  let notiMsg;
  const calltheApi = (nativePayload, buttonNumber) => {
    setIsSavingIdentityProvider(true);
    notiMsg = "Registering identity provider initiated";
    dispatch(setNewNotification({ payload: { text: notiMsg, icon: "notiInfo" } }));
    message.info(notiMsg);

    let data = JSON.stringify(nativePayload);

    let updateAuthSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: updateAuthSettingsURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    Axios.request(config)
      .then((response) => {
        if (response.data[0].status == "Error") {
          notiMsg = "Registering identity provider failed, please try again";
          dispatch(
            setNewNotification({ payload: { text: notiMsg, icon: "failed" } })
          );

          message.error(notiMsg);
          setIsSavingIdentityProvider(false);

          return;
        }

        setIsSavingIdentityProvider(false);

        notiMsg = "Registered identity provider sucessfully";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "good" } })
        );

        message.success(notiMsg);
      })
      .catch((error) => {
        console.log(error);

        setIsSavingIdentityProvider(false);

        notiMsg = "Registering identity provider failed, please try again";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "failed" } })
        );

        message.error(notiMsg);
      });
  };

  return (
    <div className="App authentication-settings-container">
      <div className="reverseproxy-header">
        {/* <h2 style={{ fontFamily: "ABBvoice-medium" }}>
          Authentication Settings
        </h2> */}
      </div>
      {/* <hr className="horizontal-line" /> */}

      <div className="create-edge-form">
        <h4
          style={{
            marginBottom: "1px",
          }}
          className="text_1"
        >
          Register default Identity Provider for Connect-On-Demand
          Authentication Service.
        </h4>

        <Form layout="vertical" onFinish={registerIdentityProvider}>
          <Form.Item
            label="Provider"
            // name="Provider"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providervalue}
              onChange={(e) => {
                setProviderValue(e.target.value);
              }}
            />
            {/* <Input value={"fa"}/> */}
          </Form.Item>

          <Form.Item
            label="Name"
            // name="Name"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={namevalue}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Client Id"
            // name="ClientId"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={clientIdvalue}
              onChange={(e) => {
                setClientIdValue(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Provider Metadata"
            // name="ProviderMetadata"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providerMetaDatavalue}
              onChange={(e) => {
                setProvierMetaDataValue(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Provider URL"
            // name="ProviderURL"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providerUrlValue}
              onChange={(e) => {
                setProviderUrlValue(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            // name="certificate"
            label="Upload Certificate"
            rules={[{ required: true }]}
            class="text_1"
            onChange={(e) => {
              uploadFile(e);
            }}
            style={{ marginBottom: "20px" }}
          >
            {/* <Upload name="logo" action="/upload.do" listType="picture"> */}
            {/* <Upload name="logo">
              <Button>Click to upload</Button>
            </Upload> */}
            <Input type="file" accept=".pem,.cert,.cer,.crt" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "10px" }}>
            <div
              className="button-options"
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                // className="button-option"
                htmlType="submit"
                loading={isSavingIdentityProvider}
                // text="Register Identity Provider"
                style={{
                  background: "black",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  boxShadow: "unset",
                  cursor: "pointer",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontFamily: "ABBVoice",
                }}
              >
                Apply
              </Button>
              <Button
                // type="primary"
                // className="button-option"

                // text="Cancel"
                style={{
                  borderRadius: "100px",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>

        {/* <Form layout="vertical" onFinish={registerIdentityProvider}>
          {AuthenticationSettingsData?.properties?.map((ele, key) => (
            // <Form.Item rules={[{ required: true }]} style={{ marginBottom: "10px" }} key={key} class="text_1">
              renderInputs(ele)
            // </Form.Item>
          ))}

           <Form.Item style={{ marginBottom: "10px" }}>
            <div className="button-options" style={{ marginLeft: "auto",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end', }}>
              <Button
                type="primary"
                // className="button-option"
                htmlType="submit"
                loading={isSavingIdentityProvider}
                // text="Register Identity Provider"
                style={{
                  background: "black",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  boxShadow: "unset",
                  cursor: "pointer",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontFamily: "ABBVoice",
                }}
              >
                Apply
              </Button>
              <Button
            // type="primary"
            // className="button-option"
    
            // text="Cancel"
            style={{
              borderRadius: "100px",
              marginLeft: "20px",
              fontWeight: "500",
            }}
          >
            Cancel
          </Button>
            </div>
          </Form.Item>
        </Form> */}
      </div>
    </div>
  );
}

export default Widget;
