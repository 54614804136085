import Axios from "axios";
import React, { useState } from "react";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { generateTime } from "../../constants/commonFunction";
import {
  setNewNotification,
  setPageNotifications,
} from "../../reduxStore/actions";

function Widget() {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();

  const [isSavingEdgeAdministrator, setIsSavingEdgeAdministrator] =
    useState(false);
  const edgeAdminSettingsData = useSelector(
    (state) => state.setEdgeAdminSettings
  );
  const targets = [
    "edgeauthadminapi.registerIdentityProvider",
    "edgeauthadminapi.registerEdgeAdministrator",
  ];

  let payload = [
    {
      sectionName: "authentication",
      parentTypeId: "",
      properties: [
        {
          value: {},
          targets: "",
        },
      ],
    },
  ];

  let notiMsg;
  const registerEdgeAdministrator = (values) => {
    payload[0].properties[0].value = values;
    payload[0].properties[0].targets = targets[1];

    calltheApi(payload, 1);
  };

  const calltheApi = (nativePayload, buttonNumber) => {
    setIsSavingEdgeAdministrator(true);
    notiMsg = "Registering edge administrator initiated";
    dispatch(setNewNotification({ payload: { text: notiMsg, icon: "notiInfo" } }));
    message.info(notiMsg);

    let data = JSON.stringify(nativePayload);

    let updateAuthSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: updateAuthSettingsURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    Axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

        setIsSavingEdgeAdministrator(false);
        notiMsg = "Registered edge administrator sucessfully"
        dispatch(setNewNotification({ payload: { text: notiMsg, icon: "good" } }));
        message.success(notiMsg);
       
      })
      .catch((error) => {
        console.log(error);

        setIsSavingEdgeAdministrator(false);
        notiMsg = "Registering edge administrator failed, please try again."
        dispatch(setNewNotification({ payload: { text: notiMsg, icon: "failed" } }));
        message.error(
         notiMsg
        );
       
      });
  };
  return (
    <div>
      <div className="create-edge-form">
        <h4
          style={{
            // marginTop: "30px",
            marginBottom: "3px",
          }}
          class="text_1"
        >
          Register Edge Administrator user for Connect-On-Demand Authentication
          Service
        </h4>

        <Form layout="vertical" onFinish={registerEdgeAdministrator}>
          <Form.Item
            label="Provider Id"
            name="ProviderId"
            class="text_1"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              defaultValue={edgeAdminSettingsData?.properties?.[1]?.value}
            />
          </Form.Item>

          <Form.Item
            label="User Principal Name"
            name="UserPrincipalName"
            class="text_1"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              defaultValue={edgeAdminSettingsData?.properties?.[2]?.value}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="Name"
            class="text_1"
            rules={[{ required: true }]}
            style={{ marginBottom: "20px" }}
          >
            <Input
              defaultValue={edgeAdminSettingsData?.properties?.[0]?.value}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "10px" }}>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                // className="button-option"
                loading={isSavingEdgeAdministrator}
                htmlType="submit"
                style={{
                  background: "black",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  boxShadow: "unset",
                  cursor: "pointer",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontFamily: "ABBVoice",
                }}
              >
                Apply
              </Button>

              <Button
                // type="primary"
                // className="button-option"
                // text="Cancel"
                style={{
                  borderRadius: "100px",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Widget;
