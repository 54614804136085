import React, { useState, useLayoutEffect, useEffect, useMemo } from "react";
import Heading from "../SectionHeading/Heading";
import "./Details.css";
import { routes } from "../../constants/RoutesConstants";
import { IoIosSettings } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmUpgradeDialouge,
  setApiReloader,
  setEdgeDetails,
  setIsEdgeDeploying,
  setNewNotification,
  showEdgeSettings,
} from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { RiSettings5Fill } from "react-icons/ri";
import { IoSyncSharp } from "react-icons/io5";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import UpdateInstallEdge from "../UpdateInstallEdge/UpdateInstallEdge";
import { Button, Popover, Table, Modal, Form, Input, message } from "antd";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { generateTime } from "../../constants/commonFunction";
import { setPageNotifications } from "../../reduxStore/actions";
import { GeneralConstants } from "../../constants/GeneralConstants";
import ReactMarkdown from "react-markdown";

function Details({ isSetting, props }) {
  const dispatch = useDispatch();
  // const [data, setData] = useState(detailsData);
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  let data = useSelector((state) => state.setEdgeDetails.data);
  let isLoading = useSelector((state) => state.setEdgeDetails.isLoading);
  const [pathname, setPathname] = useState("");
  let isEdgeDeploying = useSelector((state) => state.setIsEdgeDeploying);
  let isEdgeUpdateAvailable = useSelector(
    (state) => state.isEdgeUpdateAvailable
  );
  const [currentStatus, setCurrentStatus] = useState("");
  let [count, setCount] = useState(0);
  const clickedApply = useSelector((state) => state.confirmUpgradeDialouge);
  const [openNotification, setOpenNotification] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const platformType = useSelector(
    (state) => state.setEdgeDetails?.data?.[4]?.detail
  );
  const [showLisenceModal, setShowLisenceModal] = useState(false);  
  const licenceContent = useMemo(() => {
    console.log("The License Content", props?.licenseText);
    return props?.isLoading
      ? "Loading..."
      : atob(props?.licenseText || "") || "Not configured.";
  }, [props?.isLoading]);

  const progresConstant = GeneralConstants.progresConstant;

  const updateEdge = async () => {
    dispatch(confirmUpgradeDialouge({ payload: true }));
    setUpdateModalVisible(false);
  };

  const handleUpdateOk = () => {
    setUpdateModalVisible(true);
  };

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
  };

  useLayoutEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);
  const [AppupdateModalVisible, setAppUpdateModalVisible] = useState(false);
  const [applicationTypeIdToUpdate, setApplicationTypeIdToUpdate] =
    useState(null);
  const [updateload, setUpdateLoad] = useState(false);
  const [typeId, setTypeId] = useState("");
  const [settingData_typeId, setsettingData_typeId] = useState({});
  useEffect(() => {
    if (window.location.pathname.includes("appDetails")) {
      const isAppInstalled = queryParam.get("isAppInstalled");
      const settingData = queryParam.get("settingData");
      setsettingData_typeId(JSON.parse(settingData));
      const typee = JSON.parse(settingData).type;
      setTypeId(settingData_typeId.type);
      if (isAppInstalled == "true") {
        let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
          "*edgeId*",
          edgeId
        );
        checkAppUpdate = checkAppUpdate.replace("*typeId*", typee);
        const fetchUpdateStatus = async () => {
          try {
            const response = await Axios.get(checkAppUpdate);

            setIsAppUpdateAvailable(
              response?.data?.[0]?.applicationUpdateAvailable
            );
          } catch (error) {
            console.error("Error fetching update status:", error);
          }
        };
        fetchUpdateStatus();
      }
    }
  }, []);

  const showUpdateModal = (type) => {
    setApplicationTypeIdToUpdate(type);
    setAppUpdateModalVisible(true);
  };
  const handleAppUpdateCancel = () => {
    setAppUpdateModalVisible(false);
  };

  const handleAppUpdateOk = () => {
    handleUpdateApplication(applicationTypeIdToUpdate);
  };

  let AppUpdate = apiEndPoints.UPDATE_APPLICATION.replace("*edgeId*", edgeId);
  let notiMsg = "";
  const handleUpdateApplication = async (type) => {
    setUpdateLoad(true);
    // setCurrentStatus(0)
    AppUpdate = AppUpdate.replace("*type*", type);
    try {
      // setIsAppUpdateAvailable((prevState) => ({ ...prevState, [type]: false }));
      const response = await Axios.post(AppUpdate);
      if (response.status === 200) {
        setUpdateLoad(false);
        notiMsg = "Application update initialized successfully";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "good" } })
        );
        message.success(notiMsg);

        setIsAppUpdateAvailable(false);
        setAppUpdateModalVisible(false);
        refreshCheckAvailability();
        refreshgetDetails();
      } else {
        setUpdateLoad(false);
        notiMsg = "Application update initialized failed, please try again";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "failed" } })
        );
        message.error(notiMsg);

        setAppUpdateModalVisible(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateLoad(false);
      notiMsg = "Application update initialized failed, please try again";
      dispatch(
        setNewNotification({ payload: { text: notiMsg, icon: "failed" } })
      );
      message.error(notiMsg);

      setAppUpdateModalVisible(false);
    }
  };

  const refreshCheckAvailability = async () => {
    let toGetAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
      "*edgeId*",
      edgeId
    );
    toGetAppUpdate = toGetAppUpdate.replace(
      "*typeId*",
      applicationTypeIdToUpdate
    );
    const response = await Axios.get(toGetAppUpdate);
    setIsAppUpdateAvailable(response?.data?.[0]?.applicationUpdateAvailable);
    return response.data;
  };

  const refreshgetDetails = async () => {
    let toGetAppDetails =
      apiEndPoints.FETCH_INSTALLED_APPLICATION_DETAILS.replace(
        "*edgeId*",
        edgeId
      );
    toGetAppDetails = toGetAppDetails.replace(
      "*typeId*",
      applicationTypeIdToUpdate
    );
    const response = await Axios.get(toGetAppDetails);
    updateTheData(response.data);
    return response.data;
  };

  const updateTheData = (data) => {
    dispatch(
      setEdgeDetails({
        payload: {
          data: [
            {
              heading: "Application Name",
              detail: data?.name,
            },
            {
              heading: "Cluster Version",
              detail: data?.version,
            },
            {
              heading: "Description",
              detail: data?.description,
            },
          ],
          isLoading: false,
        },
      })
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "connectondemand":
        return "Connect on demand";
      case "Standalone":
        return "Standalone";
      case "unknown":
        return "-";
      default:
        return status;
    }
  };

  // const updateThisEdge = () =>{
  //   let updateEdgeURL = apiEndPoints.UPDATE_EDGE.replace(
  //   "*edgeId*",
  //   edgeId
  // );
  // let config = {
  //   method: 'get',
  //   maxBodyLength: Infinity,
  //   url: updateEdgeURL,
  //   headers: { }
  // };

  // Axios.request(config)
  // .then((response) => {
  //   console.log(JSON.stringify(response.data));
  // })
  // .catch((error) => {
  //   console.log(error);
  // });

  // }

  useEffect(() => {
    if (currentStatus !== "") {
      // console.log(oldNotifications);
      AddNotification(`${progresConstant[0]}`, "idUpdateEdge", "update");
    }
  }, [currentStatus]);

  const AddNotification = (notiText, notiId, update) => {
    if (update == "update") {
      let index = oldNotifications.newData.findIndex(
        (p) => p.notiId == `${notiId}`
      );
      let previosData = [...oldNotifications.newData];
      if (index == -1) {
        previosData.unshift({
          notiId: `${notiId}`,
          text: `${notiText}`,
          time: generateTime(),
          ele: (
            <UpdateInstallEdge
              currentStatus={`${currentStatus == "" ? 0 : currentStatus}`}
              openNotification={openNotification}
              time={generateTime()}
              key={generateTime()}
            />
          ),
        });
      } else if (index >= 0) {
        previosData[index] = {
          notiId: `${notiId}`,
          text: `${notiText}`,
          time: generateTime(),
          ele: (
            <UpdateInstallEdge
              currentStatus={`${currentStatus == "" ? 0 : currentStatus}`}
              openNotification={openNotification}
              time={generateTime()}
              key={generateTime()}
            />
          ),
        };
      }
      dispatch(
        setPageNotifications({
          payload: {
            isNew: true,
            data: oldNotifications.data,
            newData: previosData,
            // ...oldNotifications.newData,
          },
        })
      );
    } else {
      dispatch(
        setPageNotifications({
          payload: {
            isNew: true,
            data: oldNotifications.data,
            newData: [
              {
                notiId: `${notiId}`,
                text: `${notiText}`,
                time: generateTime(),
              },
              ...oldNotifications.newData,
            ],
          },
        })
      );
    }
  };

  const updateThisEdge = () => {
    // Clear correlationId from localStorage

    function CreateGuid() {
      function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
      }
      return _p8() + _p8(true) + _p8(true) + _p8();
    }
    if (platformType != "unknown") {
      const guid = CreateGuid();
      localStorage.setItem("correlationId", guid);
    }

    let updateEdgeURL = apiEndPoints.UPDATE_EDGE.replace("*edgeId*", edgeId);
    updateEdgeURL = updateEdgeURL.replace(
      "*correlationId*",
      localStorage.getItem("correlationId")
    );
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: updateEdgeURL,
      headers: {},
    };

    if (currentStatus == "") {
      setCurrentStatus(0);
    }

    Axios.request(config)
      .then((response) => {
        response.data = {};
        dispatch(setIsEdgeDeploying({ payload: true }));
        if (currentStatus == 5) {
          dispatch(setIsEdgeDeploying({ payload: false }));
          dispatch(setApiReloader({ payload: 0 }));
        }
        if (currentStatus != 5) {
          setTimeout(() => {
            setCount((prev) => prev + 1);
          }, 10000);
        }

        let keyss = Object.keys(response.data);
        if (keyss.length > 0) {
          setCurrentStatus(Math.max(...keyss));
        }
      })
      .catch((error) => {
        dispatch(setIsEdgeDeploying({ payload: false }));
        dispatch(setApiReloader({ payload: 0 }));
        setCurrentStatus(-1);
        console.log(error);
      });
  };

  useEffect(() => {
    if (clickedApply) {
      updateThisEdge();
      setOpenNotification(true);
    }
  }, [count, clickedApply]);

  return (
    <div className="App detailsApp">
      <span
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        {pathname.includes("appDetails") ? (
          <Heading additionalClass="overFlowText" text={data?.[0]?.detail} />
        ) : (
          <Heading text="Edge Details" />
        )}
        {isLoading && <SpinnerLoader style={{ marginRight: "auto" }} />}
        <div style={{ display: "flex", flexDirection: "row", rowGap: "0px" }}>
          {isEdgeUpdateAvailable ? (
            <button
              style={{
                cursor: !isEdgeUpdateAvailable ? "" : "pointer",
                // marginLeft: "auto",
                fontSize: "22px",
                // marginRight: "3px",
                marginBottom: "-7px",
                background: "unset",
                border: "none",
                // alignItems: "center",
                // justifyContent: "center",
              }}
              // disabled={!isEdgeUpdateAvailable && isEdgeDeploying}
              disabled={!isEdgeUpdateAvailable || isEdgeDeploying}
              onClick={() => {
                dispatch(confirmUpgradeDialouge({ payload: true }));
              }}
            >
              <WithTooltip>
                <IoSyncSharp />
                <Tooltip>
                  <p>Update Edge</p>
                </Tooltip>
              </WithTooltip>
            </button>
          ) : (
            ""
          )}

          {isSetting && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: "10px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  fontSize: "22px",
                  // marginRight: "10px",
                  marginBottom: "-7px",
                  background: "unset",
                  border: "none",
                }}
                onClick={() => {
                  dispatch(showEdgeSettings({ payload: true }));
                }}
                disabled={isEdgeDeploying}
              >
                <WithTooltip>
                  <RiSettings5Fill />
                  <Tooltip>
                    <p>Settings</p>
                  </Tooltip>
                </WithTooltip>
              </button>
            </div>
          )}
        </div>

        {pathname.includes("appDetails") && isAppUpdateAvailable && (
          <button
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              fontSize: "22px",
              marginRight: "10px",
              marginBottom: "-7px",
              background: "unset",
              border: "none",
            }}
            // onClick={() => showUpdateModal()}
          >
            <WithTooltip>
              <IoSyncSharp
                style={{
                  color: "#1f1f1f",
                  cursor: "pointer",
                }}
                onClick={() => showUpdateModal(settingData_typeId.type)}
                // onClick={() => console.log(settingData_typeId.type)}
              />
              <Tooltip>
                <p>Update Available</p>
                <p>Latest Version {`${settingData_typeId.version}`}</p>
              </Tooltip>
            </WithTooltip>
          </button>
        )}
      </span>
      {/* <hr style={{marginTop: '30px' }}/> */}
      <div className="edgeDetails">
        <div className="leftsection" style={{ width: "100%" }}>
          {data.length > 0 &&
            !isLoading &&
            data?.map((d, i) => (
              <>
                {pathname.includes("appDetails") ? (
                  ""
                ) : (
                  <span className="heading">
                    {d.heading == "Connectivity status"
                      ? "Connectivity Mode"
                      : d.heading}
                  </span>
                )}
                {pathname.includes("edgedetails") && (
                  <span
                    className="detail"
                    style={
                      d?.italic && { fontStyle: "italic", color: "#b7b7b7" }
                    }
                  >
                    {renderStatus(d.detail)}
                  </span>
                )}

                {pathname.includes("appDetails") && i > 0 && (
                  <span className="detail" style={{ marginTop: "10px" }}>
                    {d.detail}
                  </span>
                )}
              </>
            ))}

          {data.length == 0 && !isLoading && <NoDataComponent />}
          {pathname.includes("appDetails") &&
          <span
          style={{
            color: "#0052ff",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "500",
            border: "0px",
            fontFamily: "ABBVoice",
            fontSize: "15px",
            boxShadow: "none",
            marginTop: "20px",
          }}
          onClick={() => {
            setShowLisenceModal(true);
          }}
        >
          Open Source Lisence
        </span>
        }
        </div>
      </div>
      <Modal
        title="Update Edge"
        open={updateModalVisible}
        onCancel={handleUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please Confirm you want to Update the Edge.
        </p>
        <Button
          style={{
            marginLeft: "60%",
            background: "#1f1f1f",
            alignItems: "center",
            borderRadius: "30px",
            cursor: "pointer",
            color: "white",
            fontFamily: "ABBVoice Regular",
          }}
          type="primary"
          onClick={updateEdge}
        >
          Update
        </Button>
        <Button
          style={{
            borderRadius: "30px",
            marginTop: "20px",
            marginLeft: "20px",
            fontWeight: "500",
            fontFamily: "ABBVoice Regular",
          }}
          onClick={handleUpdateCancel}
        >
          Cancel
        </Button>
      </Modal>
      <Modal
        title="Update Application?"
        open={AppupdateModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleAppUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please confirm to update the application.
        </p>
        <Form onFinish={handleUpdateApplication} layout="vertical">
          <Button
            style={{
              marginLeft: "55%",
              background: "#1f1f1f",
              // display: "flex",
              alignItems: "center",
              borderRadius: "30px",
              // padding: "2px 10px",
              cursor: "pointer",
              color: "white",
              fontFamily: "ABBVoice Regular",
            }}
            type="primary"
            onClick={handleAppUpdateOk}
            loading={updateload}
          >
            Update
          </Button>
          <Button
            style={{
              borderRadius: "30px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              fontFamily: "ABBVoice Regular",
            }}
            onClick={handleAppUpdateCancel}
          >
            Cancel
          </Button>
        </Form>
      </Modal>
      <Modal
        centered
        open={showLisenceModal}
        onOk={() => setShowLisenceModal(false)}
        onCancel={() => setShowLisenceModal(false)}
        width="80%"
      >
        <div
          className="Heading_container overFlowText"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "22%",
            fontFamily: "ABBVoice",
            // marginTop: "-43px"
          }}
        >
          <Heading
            additionalClass="overFlowText"
            text="Open Source Lisence"
          />
        </div>

        <hr style={{ marginTop: "0px" }} />
        <>
          {/* {view ? (
            <span className="heading_2">
              <NoDataComponent />
            </span>
          ) : ( */}
            <ReactMarkdown children={licenceContent} />
          {/* )} */}
        </>
      </Modal>
    </div>
  );
}

export default Details;
