import React from "react";
import { PiWarningFill, PiWarningCircleFill } from "react-icons/pi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { RxCheck } from "react-icons/rx";
import { FaTimesCircle } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { Progress } from "antd";
import { General_color } from "../../constants/GeneralConstants";


function WarningIcons({ type, value }) {
  if (type == "critical" || type == "WARN" || type == "unknownStatus" || type == "Error" || type=="Disconnected") {
    return (
      <PiWarningCircleFill style={type == "unknownStatus" ? { fontSize: "23px", color: General_color.disable } : { fontSize: "23px", color: General_color.error}} />
    );
  } else if (type == "medium" || type == "INFO" || type == "Pending") {
    return <PiWarningFill style={{ fontSize: "23px", color: General_color.warning }} />;
  } else if (type == "_good") {
    return <GrStatusGood style={{ fontSize: "23px", fill: General_color.success }} />;
  }
  else if (type == "spiral") {
    let state_color = "";

    if (value >= 0 && value <= 50) {
      state_color = General_color.success //green color
    }
    if (value >= 51 && value <= 80) {
      state_color = General_color.warning //yellwo color
    }
    if (value >= 81 || value > 100) {
      state_color = General_color.error //red color
    }
    return (
      <CircularProgressbar
        value={value}
        strokeWidth={15}
        styles={buildStyles({
          pathColor: `${state_color}`,
          textColor: "#f88",
          trailColor: "#d6d6d6",
          backgroundColor: "#3e98c7",
        })}
      />
    );
  } else if (type == "good" || type == 'Installed' || type == "Running" || type == "Connected") {
    return (
      <span
        style={{
          backgroundColor: General_color.success,
          borderRadius: "100px",
          padding: "2px 3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "13px",
          height: "15px",
        }}
      >
        <RxCheck style={{ fontSize: "15px", color: "#fff" }} />
      </span>
    );
  } else if (type == "failed") {
    return <FaTimesCircle style={{ fontSize: "20px", color: General_color.error }} />;
  } else if (type == "progressBar") {
    value = value * 20;
    return (
      // <CircularProgressbar
      //   value={value}
      //   strokeWidth={15}
      //   styles={buildStyles({
      //     pathColor: value==100?"#477a43":'#000',
      //     textColor: "#f88",
      //     trailColor: "#d6d6d6",
      //     backgroundColor: "#3e98c7",
      //   })}
      // />
      <Progress percent={value} status="active" strokeColor={{ from: '#3366ff', to: '#477a43' }} showInfo={false} />
    );
  }else if(type == "notiInfo"){
  return <PiWarningCircleFill style={{fontSize: "23px", color: General_color.info }} />
  }
  else {
    return <></>;
  }
}

export default WarningIcons;
