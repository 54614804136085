const setMetrics = ({ payload }) => {
  return {
    type: "SET_METRICS",
    payload,
  };
};

const setGraph = ({ payload }) => {
  return {
    type: "SET_GRAPH",
    payload,
  };
};

const setEventLog = ({ payload }) => {
  return {
    type: "SET_EVENTLOG",
    payload,
  };
};

const setEdges = ({ payload }) => {
  return {
    type: "SET_EDGES",
    payload,
  };
};

const setEdgesMetrics = ({ payload }) => {
  return {
    type: "SET_EDGES_METRICS",
    payload,
  };
};

const setAvailableApps = ({ payload }) => {
  return {
    type: "SET_AVAILABLEAPPS",
    payload,
  };
};

const setInstalledApps = ({ payload }) => {
  return {
    type: "SET_INSTALLEDAPPS",
    payload,
  };
};

const setBasicEdgeSettings = ({ payload }) => {
  return {
    type: "SET_BASIC_EDGE_SETTINGS",
    payload,
  };
};

const setRouterEdgeSettings = ({ payload }) => {
  return {
    type: "SET_ROUTER_EDGE_SETTINGS",
    payload,
  };
};

const setBrokerEdgeSettings = ({ payload }) => {
  return {
    type: "SET_BROKER_EDGE_SETTINGS",
    payload,
  };
};

const setAuthenticationSettings = ({ payload }) => {
  return {
    type: "SET_AUTHENTICATION_SETTINGS",
    payload,
  };
};

const setEdgeAdminSettings = ({ payload }) => {
  return {
    type: "SET_EDGE_ADMIN_SETTINGS",
    payload,
  };
};


const showEdgeSettings = ({ payload }) => {
  return {
    type: "SHOW_EDGE_SETTINGS",
    payload,
  };
};

const confirmDialouge = ({ payload }) => {
  return {
    type: "CONFIRM_DIALOUGE",
    payload,
  };
};

const deploymentProgess = ({ payload }) => {
  return {
    type: "CONFIRM_DEPLOYMENT",
    payload,
  };
};

const setRoutesModels = ({ payload }) => {
  return {
    type: "SET_ROUTES_MODEL",
    payload,
  };
};

const setSummaryVisible = ({ payload }) => {
  return {
    type: "SET_SUMMARY_VISIBLE",
    payload,
  };
};

const setEdgeDetails = ({ payload }) => {
  return {
    type: "SET_EDGE_DETAILS",
    payload,
  };
};

const setInventory = ({ payload }) => {
  return {
    type: "SET_INVENTORY",
    payload,
  };
};

const setEdgeStatus = ({ payload }) => {
  return {
    type: "SET_EDGE_STATUS",
    payload,
  };
};

const setApiReloader = ({ payload }) => {
  return {
    type: "SET_API_RELOAD",
    payload,
  };
};

const setIsEdgeDeploying = ({ payload }) => {
  return {
    type: "SET_EDGE_DEPLOYING",
    payload,
  };
};

const isEdgeUpdateAvailable = ({ payload }) => {
  return {
    type: "SET_EDGE_UPDATE_AVAILABLE",
    payload,
  };
};

const setInstallAppsReloader = ({ payload }) => {
  return {
    type: "SET_INSTALL_APP_RELOAD",
    payload,
  };
};

const confirmUpgradeDialouge = ({ payload }) => {
  return {
    type: "CONFIRM_DIALOUGE",
    payload,
  };
};

const setApplicationDetails = ({ payload }) => {
  return {
    type: "SET_APPLICATION_DETAILS",
    payload,
  };
};

const setPageNotifications = ({payload}) =>{
  return {
    type: "SET_PAGE_NOTIFICATION",
    payload,
  };
}

const setNewNotification = ({payload}) =>{
  return {
    type: "SET_NEW_NOTIFICATION",
    payload,
  };
}
export {
  setMetrics,
  setGraph,
  setEventLog,
  setEdges,
  setAvailableApps,
  setEdgesMetrics,
  setInstalledApps,
  setBasicEdgeSettings,
  setRouterEdgeSettings,
  setBrokerEdgeSettings,
  showEdgeSettings,
  confirmDialouge,
  deploymentProgess,
  setRoutesModels,
  setSummaryVisible,
  setEdgeDetails,
  setInventory,
  setEdgeStatus,
  setApiReloader,
  setIsEdgeDeploying,
  isEdgeUpdateAvailable,
  setInstallAppsReloader,
  confirmUpgradeDialouge,
  setApplicationDetails,
  setPageNotifications,
  setAuthenticationSettings,
  setEdgeAdminSettings,
  setNewNotification
};
