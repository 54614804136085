import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaBell, FaUserCircle } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import "./Widget.css";
import { commonAuthHelper } from "../../App";
import { AppTopNaviItem } from "@abb/abb-common-ux-react";
import { envSettings } from "../../EnviornmentSettings/Settings";
import { UserMenu } from "@abb/abb.ia.components.react";
import { Alert, Breadcrumb, Button } from "antd";
import { HashRouter, Link, Route, Routes, useLocation } from "react-router-dom";
import Alledges from "../../pages/Alledges";
import AllTenantapplications from "../../pages/Alltenantapplications";
import { AiOutlineRight } from "react-icons/ai";
import { object } from "underscore";
import { Popover, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GeneralConstants } from "../../constants/GeneralConstants";
import { TiTimes } from "react-icons/ti";
import WarningIcons from "../WarningIcons/WarningIcons";
import { setPageNotifications } from "../../reduxStore/actions";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { generateTime } from "../../constants/commonFunction";

function Widget() {
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  let new_pageNotifications = useSelector(
    (state) => state.setPageNotifications
  );
  let newNotification = useSelector(state => state.setNewNotification);

  const token = commonAuthHelper.getAccessToken();
  const decodedToken = token && token !== null ? jwt_decode(token) : "";
  const decodedTokenStr = JSON.stringify(decodedToken);
  const authDataToken = JSON.parse(decodedTokenStr);
  const [isinstalled, setIsInstalled] = useState(false);
  const queryParam = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const [pageNotifications, set_PageNotifications] = useState([]);
    const [sudo,setupdate] = useState(0)
  useEffect(() => {
    set_PageNotifications(new_pageNotifications);
   
  }, [new_pageNotifications]);


  const AddNotification = (text, icon) => {
    let tp = oldNotifications.newData;
    tp.unshift({
      notiId: generateTime(),
      icon: `${icon}`,
      text: `${text}`,
      time: generateTime(),
    });
    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: tp,
        },
      })
    );
  };

  useEffect(()=>{
    if(newNotification.text=='' || newNotification.icon==""){
      return
    }
    AddNotification(newNotification.text, newNotification.icon)

  },[newNotification])

  useEffect(() => {
    if (window.location.pathname === "/appDetails") {
      const queryParams = new URLSearchParams(window.location.search);
      const isAppInstalledParam = queryParams.get("isAppInstalled");
      setIsInstalled(isAppInstalledParam === "true");
    }
  }, [queryParam]);
  const userRoles =
    authDataToken && authDataToken.roles ? authDataToken.roles : [];
  let userNameToBeDisplayed = "";
  userNameToBeDisplayed =
    authDataToken && authDataToken.name ? authDataToken.name : "";
  const [showUserProfile, toggleUserProfile] = React.useState(false);

  const [visibleNotification, Visible_notificaiton] = useState(false);

  const toggleProfile = () => {
    toggleUserProfile(!showUserProfile);
  };
  const closeUserProfile = () => {
    toggleUserProfile(false);
  };
  const location = useLocation();

  if (window.location.pathname === "/edgedetails") {
    const edgeNameFromParams = queryParam.get("edgeName");
    localStorage.setItem("edgeName", edgeNameFromParams);
  }

  // let edgeName = localStorage.getItem('edgeName');
  let edgeName = useSelector(
    (state) => state?.setEdgeDetails?.data?.[0]?.detail
  );

  if (edgeName == undefined || window.location.pathname === "/appDetails") {
    edgeName = localStorage.getItem("edgeName");
  }

  let appName = "";
  if (window.location.pathname == "/appDetails") {
    const settingData = queryParam.get("settingData");
    const jsonsettingData = JSON.parse(settingData);
    appName = jsonsettingData["name"];
  }
  const breadcrumbNameMap = {
    // '/alledges': [{route: "", title:"All Edges"}],
    "/alltenantapps": [{ route: "", title: "Applications" }],
    "/edgedetails": [{ route: "", title: edgeName }],
    "/allapplications": [
      { route: "/edgedetails", title: edgeName },
      { route: "", title: "Installed Applications" },
    ],
    "/appDetails": [
      { route: "/edgedetails", title: edgeName },
      {
        route: "/allapplications",
        title: isinstalled
          ? "Installed Applications"
          : "Available Applications",
      },
      { route: "", title: appName },
    ],
  };
  const extraBreadcrumbItems = breadcrumbNameMap[location.pathname] || [];

  let keys = Object.keys(breadcrumbNameMap);
  let saveTheRoutes = [];

  const userLogout = () => {
    let edgedetailsValue = "";
    if (envSettings.connectivityMode === "connected") {
      keys.map((route) => {
        saveTheRoutes.push({
          route,
          value: localStorage.getItem(route),
        });
      });
      let prev_edgeName = localStorage.getItem("edgeName");
      localStorage.clear();
      sessionStorage.clear();
      document.location.replace("/");
      saveTheRoutes.map((route) => {
        if (route.value == null) {
          return;
        }
        localStorage.setItem(route.route, route.value);
      });
      localStorage.setItem("edgeName", prev_edgeName);
    }
    commonAuthHelper.logOut();
  };

  useEffect(() => {
    localStorage.setItem(window.location.pathname, window.location);
  }, []);

  const setNavigation = (route) => {
    let prev_route = localStorage.getItem(route.route);
    window.location.href = prev_route;
  };
  const tenant_name = `${
    localStorage.getItem("tenant") !== null
      ? localStorage.getItem("tenant")
      : ""
  }`;
  let result = tenant_name.charAt(0).toUpperCase() + tenant_name.slice(1);
  const breadcrumbItems = [
    {
      title: <Link to="/"> {"Home"} </Link>,
      key: "Dashboard",
    },
  ].concat(extraBreadcrumbItems);

  const renderNotif = (stat) =>{
    return(
      <div  style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'10px'}}>
        <div>
        <WarningIcons type={stat?.icon} />
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
        <span style={{fontSize:'18px', fontFamily:'ABBVoice-medium'}}>{stat?.text}</span>
        <span style={{fontSize:'15px', fontFamily:'ABBVoice'}}>{stat?.time}</span>
        </div>
      
      </div>
    )
  }

  const NotificationContent = () => {
    return (
      <div
        style={{
          maxHeight: "400px",
          overflow: "auto",
        }}
      >
        {/* {pageNotifications.data.map((stat, key) => { */}
        {pageNotifications.newData.map((stat, key) => {
          return (
            <div
              key={key}
              style={{
                borderBottom: "1px solid #e2e2e2",
                padding: "20px 15px",
                width: "400px",
                background: "#fff",
              }}
            >
              <div
                style={{
                  // display: "flex",
                  // gap: "20px",
                }}
              >
                <div>
                      {stat?.ele==undefined?<>{renderNotif(stat)}</>:<>{stat?.ele}</>}
                      
                    </div>
                {/* <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  
                    <div style={{ marginTop: "5px" }}>
                      <WarningIcons type="good" />
                    </div>
                  
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    
                    <span
                      style={{
                        fontSize: "18px",
                        fontFamily: "ABBVoice-medium",
                      }}
                    >{`${stat.text}`}</span>
                    <span style={{ fontSize: "15px", fontFamily: "ABBVoice" }}>
                      {`${stat.time}`}
                    </span>
                    
                  </div>
                </span>
                <TiTimes
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    marginLeft: "auto",
                  }}
                /> */}
              </div>
            </div>
          );
        })}
        {/* {pageNotifications.data.length == 0 && ( */}
        {pageNotifications.newData.length == 0 && (
          <div
            style={{
              borderBottom: "1px solid #e2e2e2",
              padding: "10px 10px",
              width: "350px",
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems:'center',
                justifyContent:'center'
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "ABBVoice-medium",
                      color: "rgb(210, 210, 210)",
                    }}
                  >
                    No notifications
                  </span>
                </div>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const d = () =>{
    if (!visibleNotification) {
      if (new_pageNotifications.newData.length != 0) {
        dispatch(
          setPageNotifications({
            payload: {
              isNew: false,
              data: [
                // ...new_pageNotifications.newData,
                // ...pageNotifications.data,
              ],
              newData: [...new_pageNotifications.newData,],
            },
          })
        );
      }
    }
  }
  const showNoti = (value) => {
    Visible_notificaiton(value);
    
    d();
   
   
  };

  useEffect(()=>{
    if(oldNotifications.newData.length==1){
      if(oldNotifications?.newData?.[0]?.ele?.props?.currentStatus==0){
        showNoti(true)
      }
    }
  },[oldNotifications])
  
  return (
    <>
      <div className="header">
        <div className="menu-icon" style={{ marginBottom: "-10px" }}>
          <GiHamburgerMenu />
        </div>
        <div className="logo">
          <img src="/logo.png" alt="ABB Logo" />
        </div>
        <div className="divider"></div>
        <div className="app-name">
          Edgenius Management Console
          <div className="demo">
            {/* <br /> */}
            <Route path="/alledges" element={<Alledges />} />
            <Route path="/alltenantapps" element={<AllTenantapplications />} />
            <Route path="*" element={<span>Dashboard Page</span>} />
            <Breadcrumb separator={<AiOutlineRight fontSize={"14px"} />}>
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>
                  {item.route ? (
                    <span
                      className="ant-breadcrumb-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => setNavigation(item)}
                    >
                      {item.title}
                    </span>
                  ) : (
                    item.title
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        </div>
        <div className="spacer"></div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <div className="notification-icon">
            <Popover
              content={NotificationContent}
              placement="bottomRight"
              // trigger="click"
              // onClick={() => toggle_Notification()}
              visible={visibleNotification}
            >
              <div
                style={{
                  borderBottom: visibleNotification
                    ? "2px solid #3366ff"
                    : "2px solid transparent",
                  width: "55px",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
                onClick={() => showNoti(!visibleNotification)}
              >
                <FaBell />
                {pageNotifications?.newData?.length > 0 &&
                  pageNotifications.isNew && (
                    <div className="newNotificationContainer">
                      {pageNotifications.newData.length > 10
                        ? ``
                        : ``}
                        {/* : pageNotifications.newData.length} */}
                    </div>
                  )}
              </div>
            </Popover>
          </div>
          <div>
            <UserMenu
              className="profile-icon"
              tenant={`${
                localStorage.getItem("tenant") !== null
                  ? localStorage.getItem("tenant")
                  : ""
              }`}
              username={`${userNameToBeDisplayed}`}
              showSettings={false}
              // onSettings={showSettingsMenu}
              roles={userRoles}
              instance={""}
              isOpen={showUserProfile}
              showSwitchTenant={false}
              trigger={
                <AppTopNaviItem
                  id="UserMenuItem"
                  icon="abb/user-in-circle"
                  active={showUserProfile}
                  onClick={() => toggleProfile()}
                />
              }
              position="bottom right"
              onRequestClose={() => closeUserProfile()}
              onSignOut={() => {
                userLogout();
              }}
            />
          </div>
        </div>
        {/* <div className="user-name">{userNameToBeDisplayed}</div> */}
      </div>
    </>
  );
}

export default Widget;
