import { envSettings } from "../EnviornmentSettings/Settings";

export const apiEndPoints = {
  FETCH_METRICS: `${envSettings.metricsApiUrl}/metrics/fetchInstantMetrics`,
  FETCH_EVENTS: `${envSettings.metricsApiUrl}/logs/fetchK8sEvents`,
  FETCH_GRAPH: `${envSettings.metricsApiUrl}/metrics/fetchRangeMetrics`,

  FETCH_ALL_EDGES_APP: `${envSettings.webapiUrl}/Edge/edges?platformType=&includeApps=true&includeConnectionStatus=false&metrics=false`,
  FETCH_ALL_EDGED_APP_METRICS: `${envSettings.webapiUrl}/Edge/edges`,
  CHECK_EDGE_UPDATE: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/update/availability`,
  UPDATE_EDGE: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/update?correlationId=*correlationId*`,
  FETCH_SINGLE_EDGE_DETAILS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/info`,
  FETCH_AVAILABLE_APPS: `${envSettings.webapiUrl}/edges/*edgeId*/availableApplications`,
  FETCH_ALL_AVAILABLE_APPS: `${envSettings.webapiUrl}/applications`,
  FETCH_INSTALLED_APPS: `${envSettings.webapiUrl}/edges/*edgeId*/applications`,
  UPDATE_INSTALLED_APP: `${envSettings.webapiUrl}/updateApplicationSettings`,

  FETCH_BASIC_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings`,
  // SET_EDGE_CONFIGURATION: `${envSettings.webapiUrl}/edge/*edgeId*/edgeconfig`,
  SET_BASIC_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings?correlationId=*correlationId*`,
  SET_ALL_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings`,
  
  // FETCH_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/settings?isApplicationInstalled=*isInstalled*`,
  FETCH_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/settings`,
  FETCH_AVAILABLE_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/applications/*typeId*/settings`,
  
  INSTALL_APPLICATION: `${envSettings.webapiUrl}/installApplication`,
  FETCH_ROUTER_MODULES: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/router/models`,

  UPDATE_EDGE_AUTH_SETTINGS: `${envSettings.webapiUrl}/Edge/edgesetup/deployEdge?edgeId=*edgeId*&IsInstallation=*checkstatus*&correlationId=*correlationId*`,

  GET_EDGE_DEPLOY_STATUS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/deployment/status?correlationId=*correlationId*`,
  FETCH_STATUS_EDGE_DETAILS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/status`,
  FETCH_SYSTEM: `${envSettings.webapiUrl}/System`,
  ADD_NEW_SYSTEM: `${envSettings.webapiUrl}/System`,
  DELETE_SYSTEM: `${envSettings.webapiUrl}System/*systemId*`,
  DELETE_APPLICATION: (edgeId, type) =>
    `${envSettings.webapiUrl}/edges/${edgeId}/applications/${type}`,
  UPDATE_APPLICATION: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*type*/AppUpdate`,
  CHECK_UPDATE_APPLICATION: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/update/availability`,
  FETCH_AVAILABLE_APPLICATION_DETAILS: `${envSettings.webapiUrl}/applications/*typeId*/Details`,
  FETCH_INSTALLED_APPLICATION_DETAILS: `${envSettings.webapiUrl}/edge/*edgeId*/applications/*typeId*/Details`,
};
