import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { GrNotes } from "react-icons/gr";
import { IoMdSettings, IoIosAdd } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { routes } from "../../constants/RoutesConstants";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import Axios from "axios";
import { setEdgesMetrics } from "../../reduxStore/actions";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { Table, Tooltip } from "antd";
import { useHistory } from "react-router";

function AlledgesComponents() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState(true);
  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(false);
  const AllEdges = useSelector((state) => state.setEdgesMetrics.data);
  const isLoading = useSelector((state) => state.setEdgesMetrics.isLoading);
  const [reFactoredEdges, setRefactoredEdges] = useState([]);

  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);
  const [customHeight, setCustomHeight] = useState(0);
  const [sudoUpdate, setSudoUpdate] = useState(0);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_ALL_EDGED_APP_METRICS,
      headers: {
        // Authorization: `Bearer ${access_token}`,
      },
    };

    Axios.request(config)
      .then((response) => {
        dispatch(
          setEdgesMetrics({
            payload: { data: response.data.edgeDetails, isLoading: false },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function compare(a, b) {
    if (a.last_nom < b.last_nom) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (AllEdges.length == 0) {
      return;
    }
    let collectEdges = [];

    AllEdges.map((edge, key) => {
      collectEdges.push({
        key: edge.edgeId,
        name: edge.name,
        platformType: edge.platformType,
        location: edge.location,
        cpu: edge?.edgeMetrics?.[0]?.data?.[0]?.v,
        memory: edge?.edgeMetrics?.[1]?.data?.[0]?.v,
      });
    });

    collectEdges.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    // console.log(collectEdges)

    setRefactoredEdges(collectEdges);
  }, [AllEdges]);
  const adjustHeight = () => {
    let a = 0;
    let getMainContainer = document.getElementsByClassName("gridContainer");
    let getTheAboveApp = getMainContainer[1].getElementsByClassName("App");
    let appHeight = getTheAboveApp[0].clientHeight;
    if (isSummaryVisible == true) {
      a = 0;
    } else {
      a = 23;
    }
    let finalHeight = getMainContainer[1].clientHeight - appHeight - 100 + a;
    // console.log("value of inner height",finalHeight, appHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, [isSummaryVisible]);

  // const original_AllEdges = [
  //   {
  //     name: "Application asfdh",
  //     type:'asff12312h',
  //     connectionStatus:'connected',
  //     version: "0.1.2.4",
  //     edgeNode:'4',
  //     project:'Test Project',
  //     cpu: [{ icon: "spiral", value: "2" }],
  //     memory: [{ icon: "spiral", value: "35" }],
  //     storage: [{ icon: "spiral", value: "76" }]
  //   },
  //   {
  //     name: "Application asfdh",
  //     type:'asff12312h',
  //     connectionStatus:'connected',
  //     version: "0.1.2.4",
  //     edgeNode:'4',
  //     project:'Test Project',
  //     cpu: [{ icon: "spiral", value: "2" }],
  //     memory: [{ icon: "spiral", value: "35" }],
  //     storage: [{ icon: "spiral", value: "76" }]
  //   },

  // ];

  // const [AllEdges, setAllApplications] = useState(
  //   original_AllEdges
  // );

  const original_EdgeApplications = [
    {
      name: "Edge Application ",
      version: "0.3.2",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application ",
      version: "0.1.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.2.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.1.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.4.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.9.0.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.9.2.1",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.11.12.41",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.11.12.14",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.1.23.34",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
  ];
  const [EdgeApplications, setEdgeApplications] = useState(
    original_EdgeApplications
  );

  const AllApplicationsToAdd = [
    "Asset Hub",
    "Abb warmStorage",
    "Cloud data publisher",
    "FTP connect",
    "Asset Hub",
    "Abb warmStorage",
    "Cloud data publisher",
    "FTP connect",
  ];

  const handleChange = (value, column) => {
    console.log(value, column);
    if (view) {
      if (value.length == 0) {
        // setAllApplications(original_AllEdges);
      } else {
        // setAllApplications(
        //   original_AllEdges.filter((val) => val[column].includes(value))
        // );
      }
    } else {
      if (value.length == 0) {
        setEdgeApplications(original_EdgeApplications);
      } else {
        setEdgeApplications(
          original_EdgeApplications.filter((val) => val[column].includes(value))
        );
      }
    }
  };

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  // const GenerateTable = (applications) => {
  //   // console.log("Generate tabel data", applications);
  //   return (
  //     <>
  //           <table
  //         // style={{ width: "100%", borderCollapse: "collapse" }}
  //         id="listedges"
  //         className="fixed_header"
  //       >
  //          <thead>
  //         <tr
  //           className="tableHeading"
  //           style={{

  //             background: "white",
  //             boxShadow: "-1px 0px 0px 1px #d2d2d2",
  //           }}
  //         >
  //           <td className="edgeNameColumn staticCell">
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Edge Name
  //             </span>
  //           </td>
  //           <td className="edgeConnColumn staticCell">
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Connectivity Mode
  //             </span>
  //           </td>
  //           {/* <td>
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Status
  //             </span>
  //           </td> */}
  //           <td className="edgeLocationColumn staticCell">
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Location
  //             </span>
  //           </td>
  //           {/* <td>
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Edge Node
  //             </span>
  //           </td>
  //           <td>
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Project
  //             </span>
  //           </td> */}
  //           <td className=" edgeCPUColumn staticCell">
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               CPU
  //             </span>
  //           </td>
  //           <td className="edgeMemoryColumn staticCell">
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Memory
  //             </span>
  //           </td>
  //           {/* <td>
  //             <span className="overFlowText" style={{ width: "90%" }}>
  //               Storage
  //             </span>
  //           </td> */}
  //         </tr>
  //         </thead>
  // <tbody style={{
  //    height: `${customHeight-30}px`,
  // }}>

  //         {applications.length > 0 &&
  //           applications.map((application, i) => (
  //             <tr key={`app_${i}`}>
  //               <td className="edgeNameColumn">

  //                   <a
  //                   className="Hyper_link"
  //                   href={`${routes.EDGEDETAILS}?edgeName=${application.name}&edgeId=${application.edgeId}&platformType=${application.platformType}&location=${application.location}`}
  //                   style={{ textDecoration: "none", color: "3366FF" }}
  //                 >
  //                   <span className="overFlowText" style={{ width: "90%" }}>
  //                     {application.name}
  //                   </span>
  //                 </a>
  //               </td>
  //               <td className="smallerCell edgeConnColumn ">
  //                 <span className="overFlowText" style={{ width: "90%" }}>
  //                   {application.platformType == "unknown"
  //                     ? "-"
  //                     : "connect on demand"}
  //                 </span>
  //               </td>
  //               {/* <td><div style={{display:"flex",flexDirection: "row", alignItems: "center",columnGap: "10px"}}>
  //                 <WarningIcons type={`${application.status[0].icon}`} value=""/>{application.status[0].value}
  //                 </div>
  //                 </td> */}

  //               {/* <td>
  //                 <span className="overFlowText" style={{ width: "90%" }}>
  //                   {application.connectivityStatus=="Not Available"?"-":application.connectivityStatus}
  //                 </span>
  //               </td> */}
  //               <td className="edgeLocationColumn ">
  //                 <span className="overFlowText" style={{ width: "90%" }}>
  //                   {application.location == null ? "-" : application.location}
  //                 </span>
  //               </td>
  //               {/* <td>
  //                 <span className="overFlowText" style={{ width: "90%" }}>
  //                   {application.edgeNode}
  //                 </span>
  //               </td>
  //               <td>
  //                 <span className="overFlowText" style={{ width: "90%" }}>
  //                   {application.project}
  //                 </span>
  //               </td> */}
  //               <td className="edgeCPUColumn">
  //                 {application.edgeMetrics.length > 0 &&
  //                   application.platformType == "connectondemand" &&
  //                   tableStat([
  //                     {
  //                       icon: "spiral",
  //                       value: Math.round(application.edgeMetrics[0].data[0].v),
  //                     },
  //                   ])}
  //                 {application.platformType != "connectondemand" && (
  //                   <span className="overFlowText">-</span>
  //                 )}
  //               </td>
  //               <td className="edgeMemoryColumn">
  //                 {application.edgeMetrics.length > 0 &&
  //                   application.platformType == "connectondemand" &&
  //                   tableStat([
  //                     {
  //                       icon: "spiral",
  //                       value: Math.round(application.edgeMetrics[1].data[0].v),
  //                     },
  //                   ])}
  //                 {application.platformType != "connectondemand" && (
  //                   <span className="overFlowText">-</span>
  //                 )}
  //               </td>
  //               {/* <td>
  //               {(application.edgeMetrics.length>0&&application.platformType=="connectondemand")&&tableStat([
  //                   {
  //                     icon: "spiral",
  //                     value: Math.round(application.edgeMetrics[2].data[0].v),
  //                   },
  //                 ])}
  //                 {application.platformType!="connectondemand"&&<span className="overFlowText">-</span>}
  //               </td> */}
  //             </tr>
  //           ))}
  //           </tbody>
  //       </table>
  //       {applications.length == 0 && (
  //         <span
  //           style={{
  //             width: "100%",
  //             display: "block",
  //             textAlign: "center",
  //             fontSize: "15px",
  //             fontWeight: "900",
  //             border: "0.8px solid #d2d2d2",
  //             padding: "2px 16px",
  //           }}
  //         >
  //           No Data Found
  //         </span>
  //       )}
  //     </>
  //   );
  // };

  const columns = [
    {
      title: "Edge Name",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      // render: (name) => (
      //   <Tooltip placement="topLeft" title={name}>
      //     {name}
      //   </Tooltip>
      // ),
      render: (text) => <a className="edge-name">{text}</a>,
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Connectivity Mode",
      dataIndex: "platformType",
      width: "20%",
      ellipsis: true,
      render: (platformType) =>
        platformType == "unknown" ? "-" : <span>Connect on demand</span>,
      // responsive: ['md'],
    },
    {
      title: "Location",
      dataIndex: "location",
      ellipsis: true,
      width: "20%",
      render: (location) => (location != null ? <span> {location}</span> : "-"),
      // responsive: ['md'],
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: Math.round(cpu),
              },
            ])
          : "-",
      // responsive: ['md'],
      width: "20%",
    },
    {
      title: "Memory",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: Math.round(memory),
              },
            ])
          : "-",
      width: "20%",
      // responsive: ['md'],
    },
  ];

  const GenerateTable = (applications) => {
    // console.log("refactroresEdge", applications)
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event.target.classList.contains("edge-name")) {
                const href = `${routes.EDGEDETAILS}?edgeName=${record.name}&edgeId=${record.key}&location=${record.location}`;
                window.location.href = `${href}`;
              }
            },
          };
        }}
        rowHoverBg="#c11313"
      />
    );
  };

  return (
    <div className="App">
      {/* {AllEdges.length == 0 ? (
        "sOEMYHING"
      ) : (
        <> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Edge Devices" />
          {isLoading && <SpinnerLoader />}
        </span>
        {/* <button
          onClick={() => setShowAddApplicationPannel(!showAddApplicationPannel)}
          style={{
            marginLeft: "auto",
            background: "#d1ebff",
            display: "flex",
            alignItems: "center",
            borderRadius: "31px",
            padding: "5px 10px",
            boxShadow: " 0px 4px 6px -1px #d2d2d2",
            cursor: "pointer",
            border: 0,
          }}
          disabled
        >
          <IoIosAdd style={{ fontSize: "24px" }} /> New Edge
        </button> */}
      </div>
      <div id="tableBox">
       {AllEdges.length > 0 && !isLoading && (
          <div
            className=""
            // style={{ display: "flex", flexDirection: "row", columnGap: "5px", height:`${ isSummaryVisible?'calc(52vh - 15vh)':'calc(80vh - 22vh)'}` }}
            style={{
              height: `${customHeight}px`,

              overflow: "hidden",
            }}
          >
            <div style={{ display: "contents", backgroundColor: "#fff" }}>
              {/* {view
              ? GenerateTable(AllEdges)
              : GenerateTable(EdgeApplications)} */}
              {AllEdges.length > 0 &&
                !isLoading &&
                GenerateTable(reFactoredEdges)}
            </div>
            <div
              className="addApplicationPannel"
              style={{
                width: showAddApplicationPannel ? "30%" : "0px",
                backgroundColor: "#fff",
              }}
            >
              <table style={{ width: "100%" }}>
                <tr className="tableHeading">
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <span className="overFlowText" style={{ width: "90%" }}>
                      List of all Edges
                    </span>
                    <span
                      onClick={() =>
                        setShowAddApplicationPannel(!showAddApplicationPannel)
                      }
                      style={{
                        backgroundColor: "#000",
                        marginLeft: "auto",
                        borderRadius: "100px",
                        padding: "2px 2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <TiTick style={{ fontSize: "18px", color: "#fff" }} />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <td>
                  <input
                    type="text"
                    placeholder="Filter"

                    // onChange={(e) => handleChange(e.target.value, app_column)}
                  />
                </td> */}
                </tr>

                {AllApplicationsToAdd.map((content, i) => (
                  <tr key={i}>
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span className="overFlowText">{content}</span>
                        <span
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "20px",
                          }}
                        >
                          <GrNotes
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />{" "}
                          <IoMdSettings style={{ fontSize: "23px" }} />{" "}
                          <IoIosAdd style={{ fontSize: "24px" }} />
                        </span>
                      </span>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}

        {AllEdges.length == 0 && !isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10%",
              fontFamily: "ABBVoice-medium",
              color: "#1f1f1f",
              fontSize: "20px",
            }}
          >
            {" "}
            Welcome to Edgenius Management Console! Please Onboard your edge
            using ability platform serveices.
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default AlledgesComponents;
