import React, { useEffect, useState } from "react";
import WarningIcons from "../WarningIcons/WarningIcons";
import { MdInfo } from "react-icons/md";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { TiTimes } from "react-icons/ti";
import { GeneralConstants, General_color } from "../../constants/GeneralConstants";
import { useDispatch, useSelector } from "react-redux";
import { setPageNotifications } from "../../reduxStore/actions";
import { Progress } from "antd";
import { BiChevronRight } from "react-icons/bi";
function UpdateInstallEdge({ currentStatus, openNotification, time }) {
  const param = window.location.pathname;
  let EdgeOrApp = (param == "/appDetails");
 
  const progresConstant = GeneralConstants.progresConstant;
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useDispatch();
  let edgeDetails = useSelector(state=>state.setEdgeDetails)
  const showNotification = (showHide) => {
    let notificationId = "NotificationContainer";

    document
      .getElementById(notificationId)
      .setAttribute("style", `display:${showHide}`);
  };

  // useEffect(() => {
  //   if (!openNotification) {
  //     showNotification("block");
  //   }
  // }, [openNotification]);
  const toggleShowHide = (id) => {
    document.getElementById(`button_${id}`).classList.toggle("rotate");
    setIsExpand(!isExpand);
  };

  const listPreviousStat = () => {
    let noti = [];
    for (let num = currentStatus - 1; num >= 0; num--) {
      noti.push(num);
    }
    return noti.map((num, key) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            fontFamily: "ABBVoice",
          }}
        >
          <span>
            {" "}
            <WarningIcons type="good" />
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{`${progresConstant[num]}`}</span>
            <span style={{ color: "#b0b0b0" }}>{`completed`}</span>
          </div>
        </div>
      );
    });
  };

  return (
    // <div id="NotificationContainer" style={{ display: "none" }}>
    <div className="notification">
      <div>
       <div style={{ borderBottom:`1px solid ${General_color.disable}`, marginBottom:"5px", padding:'5px 10px' }}>
        <span style={{ fontSize: "18px", fontFamily: "ABBVoice-medium",}}>{EdgeOrApp?'App':'Edge'} &nbsp; </span>
        <span style={{ fontSize: "18px", fontFamily: "ABBVoice",}}>{edgeDetails.data[0].detail}</span>
        </div>
        </div>
      <div style={{padding:'5px 10px'}}>
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        
        {currentStatus >= 5 && <WarningIcons type="good" />}

        {currentStatus <= 4 && currentStatus != -1 && (
          <span
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MdInfo fontSize={"20px"} color="#3366FF" />{" "}
            <SpinnerLoader
              style={{
                position: "absolute",
                left: "0px",
                top: "15px",
                scale: "1.2",
              }}
            />
          </span>
        )}

        {currentStatus == -1 && <WarningIcons type="failed" />}

        <div style={{ display: "flex", flexDirection: "column" }}>
         
          <span style={{ fontSize: "18px", fontFamily: "ABBVoice-medium" }}>
            {currentStatus == -1
              ? "Edge deployment failed"
              : `${progresConstant[currentStatus]}`}
          </span>
          <span>{time}</span>
        </div>
      </div>
      {currentStatus != -1 && (
        <>
          {currentStatus <= 4 && (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {<WarningIcons type="progressBar" value={currentStatus} />}
              <span
                style={{ fontFamily: "ABBVoice-medium", marginLeft: "5px" }}
              >
                {`${currentStatus}/5`}
              </span>
            </span>
          )}

          {currentStatus > 0 && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => toggleShowHide(time)}
            >
              <BiChevronRight
                fontSize={20}
                style={{ cursor: "pointer" }}
                className=""
                id={`button_${time}`}
              />{" "}
              {!isExpand ? "More" : "Less"}
            </span>
          )}

          {!isExpand ? <></> : <div>{listPreviousStat()}</div>}
        </>
      )}
    </div>
    </div>
  );
}

export default UpdateInstallEdge;
