/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from "react";
import "./Widget.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Select, Tooltip, message } from "antd";
import { IoIosAdd } from "react-icons/io";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import {
  setNewNotification,
  setPageNotifications,
  setRouterEdgeSettings,
} from "../../reduxStore/actions";
import { generateTime } from "../../constants/commonFunction";
import { General_color } from "../../constants/GeneralConstants";

function Widget() {
  let data = useSelector((state) => state.setRouterEdgeSettings);
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  // const [newData, setNewData] = useState([])
  const [sudoState, setSudoState] = useState(0);

  const routesModel = useSelector((state) => state.setRoutesModels);
  const [routeModules, setRoutesModules] = useState([{label:'select',value:'select'}])
  
  

  useEffect(()=>{
    let refactoredRouteModel = []
    routesModel?.properties?.modelIds.map((model,key)=>{
      refactoredRouteModel.push({
        label:model,
        value:model
      })
    })
    setRoutesModules(refactoredRouteModel)
  },[routesModel])
  const [editingRouteKey, setEditingRouteKey] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [ischanged, setIsChanged] = useState(false);
  const [showAddRoutesPannel, setShowAddRoutesPannel] = useState(false);
  const [newRouteType, setNewRouteType] = useState("");
  const [newRouteModel, setNewRouteModel] = useState("");
  const [newRoute, setNewRoute] = useState("");
  const [newInitialRoutes, setNewInitialRoutes] = useState([]);
  const [addroutesEnable, setaddroutesEnable] = useState(false);

  const resetRoutes = () => {
    setNewRouteType("");
    setNewRouteModel("");
    setNewRoute("");
    setEditingRouteKey(null);
  };

  const addNewRoutes = () => {
    setIsdelete(false);
    if (!newRouteType || !newRouteModel || !newRoute) {
      return;
    }
    let payload = [
      {
        sectionName: data.sectionName,
        parentTypeId: data.parentTypeId,
        properties: [
          {
            value: {
              routes: newInitialRoutes?.properties?.[0]?.routes
                ? newInitialRoutes.properties[0].routes
                : {},
            },
            targets: data.properties[0].targets,
          },
        ],
      },
    ];
    setNewInitialRoutes(payload[0]);
    setIsChanged(true);
    let newKey;
    if (editingRouteKey != null) {
      newKey = editingRouteKey;
    } else {
      newKey = `${newRoute}_${newRouteType}_${newRouteModel}`
        .replace(/ /g, "")
        .replace(/\./g, "");
    }

    console.log(
      newKey,
      data?.properties?.[0]?.value.routes.hasOwnProperty(newKey),
      editingRouteKey
    );
    if (
      newInitialRoutes?.properties?.[0]?.value.routes?.hasOwnProperty(newKey) ==
        true ||
      data?.properties?.[0]?.value.routes?.hasOwnProperty(newKey) == true
    ) {
      // console.log(data?.properties?.[0]?.value.routes[newKey]);
      const updatedRoute = {
        configuration: {
          type: { value: newRouteType },
          targetTopic: { value: newRoute },
          model: { value: newRouteModel },
          nameFilter: { value: "*" },
        },
      };
      data.properties[0].value.routes[newKey] = updatedRoute;
      console.log(data.properties[0].value.routes[newKey]);

      dispatch(setRouterEdgeSettings({ payload: data }));

      setSudoState((prev) => prev + 1);

      return;
    } else {
      // console.log(payload)

      let addedRoute = {
        // [newKey]:{
        configuration: {
          type: {
            value: newRouteType,
          },
          targetTopic: {
            value: newRoute,
          },
          model: {
            value: newRouteModel,
          },
          nameFilter: {
            value: "*",
          },
        },
        // },
      };

      payload[0].properties[0].value.routes[newKey] = addedRoute;

      setNewInitialRoutes(payload[0]);

      // console.log("fianl Payload", payload[0]);
    }

    let tbodyRef = document
      .getElementById("myTable")
      .getElementsByTagName("tbody")[0];

    // Insert a row at the end of table
    let newRow = tbodyRef.insertRow();
    newRow.setAttribute("style", "background:#F3F3F3;");

    // Insert a cell at the end of the row
    let typeCell = newRow.insertCell();
    let routeCell = newRow.insertCell();
    let modelCell = newRow.insertCell();
    let filterCell = newRow.insertCell();

    // Append a text node to the cell
    let typeText = document.createTextNode(newRouteType);
    let routeText = document.createTextNode(newRoute);
    let modelText = document.createTextNode(newRouteModel);
    let filterText = document.createTextNode("*");

    typeCell.appendChild(typeText);
    routeCell.appendChild(routeText);
    modelCell.appendChild(modelText);
    filterCell.appendChild(filterText);

    resetRoutes();
    // }
  };

  const editRoute = (routeKey) => {
    setIsdelete(false);
    const route = data.properties[0].value.routes[routeKey].configuration;

    setNewRouteType(route.type.value);
    setNewRoute(route.targetTopic.value);
    setNewRouteModel(route.model.value);
    setEditingRouteKey(routeKey);
    setShowAddRoutesPannel(true);
  };
  const [isdelete, setIsdelete] = useState(false);

  const deleteRoute = (routeKey) => {
    const updatedRoutes = { ...data };
    delete updatedRoutes.properties[0].value.routes[routeKey];
    setIsChanged(true);
    dispatch(setRouterEdgeSettings({ payload: updatedRoutes }));
    setIsdelete(true);
  };
  let notiMsg;
  const saveData = () => {
    setIsSaving(true);

    let mainChunk = isdelete ? data : newInitialRoutes;
    console.log(mainChunk);
    mainChunk.properties[0].value.routes = {
      ...mainChunk?.properties?.[0]?.value?.routes,
      ...data.properties[0].value.routes,
    };

    let payload = JSON.stringify([mainChunk]);
    let postEdgeSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: postEdgeSettingsURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    notiMsg = "Applying router settings initiated";
    dispatch(
      setNewNotification({ payload: { text: notiMsg, icon: "notiInfo" } })
    );
    message.info(notiMsg);

    Axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsSaving(false);
        notiMsg = "Router settings applied successfully";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "good" } })
        );
        message.success(notiMsg);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        notiMsg = "Applying router settings failed, please try again";
        dispatch(
          setNewNotification({ payload: { text: notiMsg, icon: "failed" } })
        );
        message.error(notiMsg);
      });
  };

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChange = (value) => {
    // console.log(`selected ${value}`);
    setNewRouteModel(value)
  };

  const renderInputs = (ele) => {
    switch (ele?.presentationType) {
      case "Route":
        let routes = ele?.value?.routes;
        if (routes == null) routes = [];
        let routesKey = Object.keys(routes);

        return (
          <>
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 500 }} className="text_1">
                {ele?.name}
              </div>
              {/* <div style={{ width: "60px" }}> */}
              {/* <Switch defaultChecked /> */}
              <Select
                style={{ width: "200px" }}
                defaultValue={ele.valueDomain[1].split("@")[0]}
              >
                {ele?.valueDomain
                  ?.filter((e) => !e.includes("Cloud"))
                  .map((e, k) => {
                    let refined_values = e.split("@");

                    return (
                      // eslint-disable-next-line react/jsx-no-undef
                      <Option value={refined_values[0]} key={k}>
                        {refined_values[1]}
                      </Option>
                    );
                  })}
              </Select>
              {/* </div> */}
            </div>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <span
                style={{ display: "block", fontWeight: 500 }}
                className="heading_2"
              >
                Specific Routes
              </span>
              <span
                onClick={() => {
                  setShowAddRoutesPannel(true);
                  resetRoutes();
                  setaddroutesEnable(true);
                }}
                style={{
                  marginLeft: "auto",
                  background: addroutesEnable
                    ? General_color.disable
                    : "#1f1f1f",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "2px 10px",
                  // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                  cursor: "pointer",
                  color: "white",
                  fontFamily: "ABBVoice",
                  fontSize: "15px",
                }}
              >
                <IoIosAdd style={{ fontSize: "25px" }} /> Add Routes
              </span>
            </div>
            <div style={{ display: "flex", columnGap: "10px" }}>
              <table className="RoutesTable" id="myTable">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Route</th>
                    <th>Model</th>
                    <th>Filter</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {routesKey.length > 0 &&
                    routesKey.map((route, key) => (
                      <tr>
                        <td>
                          {ele.value.routes[route]?.configuration.type.value ==
                          "timeseries"
                            ? "Variable Data"
                            : ele.value.routes[route]?.configuration.type.value}
                        </td>
                        <td>
                          {
                            ele.value.routes[route]?.configuration.targetTopic
                              .value
                          }
                        </td>
                        <td>
                          {ele.value.routes[route]?.configuration.model.value}
                        </td>
                        <td>
                          {
                            ele.value.routes[route]?.configuration.nameFilter
                              .value
                          }
                        </td>
                        <td>
                          <div
                            className="action_buttons"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              rowGap: "10px",
                            }}
                          >
                            {ele.value.routes[route]?.configuration.type
                              .value !== "timeseries" && (
                              <Tooltip title="Edit Route">
                                <MdOutlineEdit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => editRoute(route)}
                                />
                              </Tooltip>
                            )}
                            {ele.value.routes[route]?.configuration.type
                              .value !== "timeseries" && (
                              <Tooltip title="Delete Route">
                                <MdOutlineDelete
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "30%",
                                  }}
                                  onClick={() => deleteRoute(route)}
                                />
                              </Tooltip>
                            )}
                            {/* <MdOutlineDelete
                              style={{ cursor: "pointer", marginLeft: "30%" }}
                            /> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div
                className="sidePannel"
                style={
                  showAddRoutesPannel
                    ? {
                        width: "60%",
                        borderLeft: "1px solid #d2d2d2",
                        padding: "0px 10px",
                        height: "auto",
                      }
                    : { width: "0%" }
                }
              >
                <span>
                  <label>Type</label>
                  <br />
                  <Select
                    value={newRouteType}
                    onChange={(e) => setNewRouteType(e)}
                    style={{ width: "100%" }}
                  >
                    <Option value="">select</Option>
                    {routesModel?.properties.types.map((type, key) => (
                      // eslint-disable-next-line react/jsx-no-undef
                      <Option key={key} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </span>
                <span>
                  <label>Route</label>
                  <br />
                  <Select
                    value={newRoute}
                    onChange={(e) => setNewRoute(e)}
                    style={{ width: "100%" }}
                  >
                    <Option selected value="">
                      select
                    </Option>

                    {routesModel?.properties.routes.map((route, key) => (
                      <Option key={key} value={route}>
                        {route}
                      </Option>
                    ))}
                  </Select>

                  
                </span>

                <span>
                  <label>Model</label>
                  <br />
                  {/* <Select
                    value={newRouteModel}
                    onChange={(e) => setNewRouteModel(e)}
                    style={{ width: "100%" }}
                  >
                    <Option value="">select</Option>

                    {routesModel?.properties.modelIds.map((model, key) => (
                      <Option key={key} value={model}>
                        {model}
                      </Option>
                    ))}
                  </Select> */}

                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={onChange}
                    // onSearch={onSearch}
                    // value={newRouteModel}
                    filterOption={filterOption}
                    options={routeModules}
                    style={{ width: "100%" }}
                  />
                </span>

                <span>
                  <label>Filter</label>
                  <br />
                  {/* <Select style={{ width: "100%" }}>
                    <Option value="*" selected>
                      *
                    </Option>
                  </Select> */}
                  <Input value="*" disabled/>
                </span>

                <span style={{ display: "flex" }}>
                  <Button
                    style={{
                      background: "black",
                      alignItems: "center",
                      borderRadius: "31px",
                      padding: "5px 10px",
                      // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                      cursor: "pointer",
                      fontWeight: "500",
                      color: "white",
                      fontFamily: "ABBVoice",
                    }}
                    onClick={addNewRoutes}
                  >
                    {editingRouteKey !== null ? "Update Route" : "Add Route"}
                  </Button>
                  <Button
                    variant="outline"
                    style={{
                      borderRadius: "100px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      padding: "5px 10px",
                      fontFamily: "ABBVoice",
                    }}
                    onClick={resetRoutes}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    style={{
                      borderRadius: "100px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      padding: "5px 10px",
                      fontFamily: "ABBVoice",
                    }}
                    onClick={() => {
                      setShowAddRoutesPannel(false);
                      setaddroutesEnable(false);
                    }}
                  >
                    Done
                  </Button>
                </span>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };
  return (
    <>
      {/* <h2 style={{fontFamily:'ABBvoice-medium'}}>{data.sectionName}</h2>
      <hr className="horizontal-line" /> */}

      <div className="routerSettingsContainer">
        {data?.properties?.map((ele, key) => (
          <div key={key}>{renderInputs(ele)}</div>
        ))}

        {/* <hr/>
          {newInitialRoutes?.properties?.map((ele, key) => (
          <div key={key}>{renderInputs(ele)}</div>
        ))} */}
        <div
          className="button-group"
          style={{
            display: "flex",
            // columnGap: "10px",
            alignItems: "baseline",
            marginTop: "20px",
          }}
        >
          {/* <Button
              type="primary"
              style={{ borderRadius: "100px", marginTop: "20px" }}
              
              >
              Apply
            </Button> */}

          <Button
            loading={isSaving}
            // icon={<PoweroffOutlined />}
            style={{
              background: !ischanged ? "#848484" : "#1f1f1f",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 10px",
              // boxShadow: " 0px 4px 6px -1px #d2d2d2",
              cursor: "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
              fontFamily: "ABBVoice",
            }}
            onClick={saveData}
            disabled={!ischanged ? true : false}
          >
            Apply
          </Button>

          <Button
            variant="outline"
            style={{
              borderRadius: "100px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              padding: "5px 10px",
              fontFamily: "ABBVoice",
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}

export default Widget;
