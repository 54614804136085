/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import "./Widget.css";
import Heading from "../SectionHeading/Heading";
import WarningIcons from "../WarningIcons/WarningIcons";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useSelector } from "react-redux";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { Tooltip } from "antd";
import { General_color } from "../../constants/GeneralConstants";

function Widget({ data, inventoryLoading }) {
  let inventorydata;
  // if(!data){
  inventorydata = useSelector((state) => state.setInventory.data);
  let isLoading = useSelector((state) => state.setInventory.isLoading);
  // }

  return (
    <div className="App inventoryApp">
      <span
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "96%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text="Inventory" />
        {isLoading && <SpinnerLoader />}
      </span>
      <div className="inventoryList">
        {!isLoading &&
          inventorydata.length > 0 &&
          inventorydata.map((i, key) => (
            <div
              style={{
                borderBottom: "1px solid #f0f0f0",
                padding: "10px",
                display: "flex",
                flexDirection: "row",
              }}
              key={key}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                }}
              >
                {i?.route ? (
                  <>
                    {i?.isEnabled == "true" ? (
                      <a
                        href={`${i.route}`}
                        style={{ textDecoration: "none", color:General_color.info }}
                        className="Hyper_link"
                      >
                        <Tooltip title="Manage applications">
                          <b style={{ fontSize: "18px" }}>{i.name}</b>
                        </Tooltip>
                      </a>
                    ) : (
                      <b style={{ fontSize: "18px" }}>{i.name}</b>
                    )}
                  </>
                ) : (
                  <b style={{ fontSize: "18px" }}>{i.name}</b>
                )}
                <span style={{ fontSize: "15px" }}>{i.details}</span>
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  // columnGap: "20px",
                  alignItems: "center",
                }}
              >
                {i.warningstatus &&
                  i.warningstatus.map((d, i) => (
                    <>
                    {d.value!==0 && (
                       <div
                       key={i}
                       style={{
                         display: "flex",
                         flexDirection: "row",
                         columnGap: "5px",
                         alignItems: "center",
                         marginLeft:'20px'
                       }}
                     >
                      
                         <>
                           <WarningIcons type={d.icon} value={d.value} />
                           <span style={{ fontWeight: "500", color: "#00308F" }}>
                             
                             {d.value.toString().length>1?d.value:d.value!=0?`0${d.value}`:d.value}
                           </span>
                         </>
                      
                     </div>
                    )}
                     
                    </>
                    
                  ))}
              </div>
            </div>
          ))}

        {!isLoading && inventorydata.length == 0 && <NoDataComponent />}
      </div>
    </div>
  );
}

export default Widget;
