import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { GrNotes } from "react-icons/gr";
import { IoMdSettings, IoIosAdd, IoIosRemove } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { Button, Popover, Table, Modal, Form, Input, message } from "antd";
import { useParams } from "react-router";
import AppSettings from "../../components/AppSettings/AppSettings";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import {
  setInstalledApps,
  setAvailableApps,
  setPageNotifications,
  setNewNotification,
} from "../../reduxStore/actions";
import { stat } from "fs";
import { FaTimesCircle } from "react-icons/fa";
import { availableTypes } from "../../Utils/Constants";
import { routes } from "../../constants/RoutesConstants";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoSyncSharp } from "react-icons/io5";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import Axios from "axios";
import { generateTime } from "../../constants/commonFunction";

function AllapplicationsComponents({ apiLoaded }) {
  // const isSummaryVisible = useSelector(state=>state.setSummaryVisible)

  const isSummaryVisible = false;
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  const [appSettingsFetched, setAppSettingsFetched] = useState(true);
  const [changeState, setChageState] = useState(0);
 
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [applicationToUpdate, setApplicationToUpdate] = useState(null);
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const [load, setLoad] = useState(false);
  const [updateload, setUpdateLoad] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const _AvailableApplications = useSelector((state) => state.setAvailableApps);
  const original_AllApplications = useSelector(
    (state) => state.setInstalledApps
  );

  const showDeleteModal = (type, edgeId) => {
    setApplicationToDelete({ type: type, edgeId: edgeId });
    setDeleteModalVisible(true);
  };

  const showUpdateModal = (type) => {
    setApplicationToUpdate({ type: type });
    setUpdateModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
  };

  const handleDeleteOk = () => {
    handleDeleteApplication(applicationToDelete);
  };

  const handleUpdateOk = () => {
    handleUpdateApplication(applicationToUpdate);
  };

  let notiMsg;
  const handleDeleteApplication = async ({ type, edgeId }) => {
    if (confirmationText.toLowerCase() === "yes") {
      try {
        setLoad(true);
        const response = await Axios.delete(
          apiEndPoints.DELETE_APPLICATION(edgeId, type)
        );

        if (response.status === 200) {
          const updatedInstalledApps = original_AllApplications.filter(
            (app) => app.type !== type
          );
          dispatch(setInstalledApps({ payload: updatedInstalledApps }));

          const uninstalledApp = original_AllApplications.find(
            (app) => app.type === type
          );

          const updatedAvailableApps = _AvailableApplications.concat([
            uninstalledApp,
          ]);
          dispatch(setAvailableApps({ payload: updatedAvailableApps }));
          setLoad(false);
          notiMsg = "Application uninstalled successfully";
          dispatch(setNewNotification({ payload: { text: notiMsg, icon: "good" } }));
          message.success(notiMsg);

          setDeleteModalVisible(false);
        } else {
          setLoad(false);
          notiMsg = "Failed to uninstall the application, please try again";
            message.error(
           notiMsg
          );
          dispatch(setNewNotification({ payload: { text: notiMsg, icon: "failed" } }));

          setDeleteModalVisible(false);
        }
      } catch (error) {
        console.error(error);
        setLoad(false);
        notiMsg = "Failed to uninstall the application, please try again";
        message.error(notiMsg);
          dispatch(setNewNotification({ payload: { text: notiMsg, icon: "failed" } }));

        setConfirmationText("");
        setIsDeleteButtonEnabled(false);

        setDeleteModalVisible(false);
      }
    } else {
      message.error("Please type 'yes' to confirm deletion.");
    }
  };

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState({});

  useEffect(() => {
    let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
      "*edgeId*",
      edgeId
    );
    checkAppUpdate = checkAppUpdate.replace("*typeId*", "All");
    const fetchUpdateStatus = async () => {
      try {
        const response = await Axios.get(checkAppUpdate);
        const updateStatusMap = {};
        response.data.forEach((app) => {
          updateStatusMap[app.applicationTypeId] =
            app.applicationUpdateAvailable;
        });
        setIsAppUpdateAvailable(updateStatusMap);
      } catch (error) {
        console.error("Error fetching update status:", error);
      }
    };
    fetchUpdateStatus();
  }, []);
  let AppUpdate = apiEndPoints.UPDATE_APPLICATION.replace("*edgeId*", edgeId);

  const handleUpdateApplication = async ({ type }) => {
    setUpdateLoad(true);
    AppUpdate = AppUpdate.replace("*type*", type);
    try {
      setIsAppUpdateAvailable((prevState) => ({ ...prevState, [type]: false }));
      const response = await Axios.post(AppUpdate);
      if (response.status === 200) {
        setUpdateLoad(false);
        notiMsg = "Application update initialized successfully";
        message.success(notiMsg);
 
          dispatch(setNewNotification({ payload: { text: notiMsg, icon: "good" } }));

        setUpdateModalVisible(false);
        refreshInstalledApps();
      } else {
        setUpdateLoad(false);
        notiMsg ="Application update initialization failed, please try again"
        message.error(
          notiMsg
        );
        dispatch(setNewNotification({ payload: { text: notiMsg, icon: "failed" } }));

        setUpdateModalVisible(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateLoad(false);
      notiMsg="Application update initialization failed, please try again"
      message.error(
        notiMsg
      );
      dispatch(setNewNotification({ payload: { text: notiMsg, icon: "failed" } }));


      setUpdateModalVisible(false);
    }
  };

  const refreshInstalledApps = async () => {
    try {
      const updatedInstalledApps = await fetchUpdatedInstalledApps();
      // const updatedInstalledApps = await original_AllApplications;
      dispatch(setInstalledApps({ payload: updatedInstalledApps }));
      handleFetchUpdateStatus();
    } catch (error) {
      console.error("Error refreshing installed apps:", error);
    }
  };

  const fetchUpdatedInstalledApps = async () => {
    let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
      "*edgeId*",
      edgeId
    );
    const response = await Axios.get(toGetInstalledApps);
    // const response = await original_AllApplications;
    return response.data;
  };

  const handleFetchUpdateStatus = async () => {
    try {
      // Call the API to fetch update status
      let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
        "*edgeId*",
        edgeId
      );
      checkAppUpdate = checkAppUpdate.replace("*typeId*", "All");
      const response = await Axios.get(checkAppUpdate);

      // Update the application update status in the state
      const updateStatusMap = {};
      response.data.forEach((app) => {
        updateStatusMap[app.applicationTypeId] = app.applicationUpdateAvailable;
      });
      setIsAppUpdateAvailable(updateStatusMap);
    } catch (error) {
      console.error("Error fetching update status:", error);
    }
  };

  // useEffect(() => {
  //   refreshInstalledApps();
  // }, []);

  const [refactoredInstalledApp, setRefactoredInstalledApp] = useState([]);

  const [sudoState, setSudoState] = useState(0);

  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(true);

  let initialPopupState = [];
  // AvailableApplications.map((app)=>{

  //   initialPopupState.push(false)
  // })

  const [open, setOpen] = useState({});

  const hide = () => {
    closeAllPopup();
  };
  const handleOpenChange = (index) => {
    // initialPopupState[index] = !initialPopupState[index]
  };

  const [selectedOpen, setSelectedOpen] = useState(0);

  useEffect(() => {
    closeAllPopup();
  }, [_AvailableApplications]);

  const closeAllPopup = () => {
    let openedModal = {};

    for (let val = 0; val < _AvailableApplications.length; val++) {
      openedModal[val] = false;
    }

    setOpen(openedModal);
  };

  const setThisOpen = (index) => {
    for (let val = 0; val < _AvailableApplications.length; val++) {
      if (index == val) {
        open[val] = true;
      } else {
        open[val] = false;
      }
    }
  };

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const showHideModules = (id) => {
    let concatId = `canbehidden_${id}`;
    let parentRowId = `parentRow_${id}`;
    document.getElementById(`add_${id}`).classList.toggle("displaynone");
    document.getElementById(`remove_${id}`).classList.toggle("displaynone");
    let allparentTd = document
      .getElementById(parentRowId)
      .querySelectorAll("td");
    for (let i = 0; i < allparentTd.length; i++) {
      allparentTd[i].classList.toggle("hiddenParentUnderline");
    }
    document.getElementById(concatId).classList.toggle("hiddenModules");
  };
  useEffect(() => {
    if (original_AllApplications.length > refactoredInstalledApp.length) {
      setView((prev) => prev + 1);
    }
    // console.log("riunned");
  });

  useEffect(() => {
    if (original_AllApplications.length == 0) {
      return;
    }

    let correctedInstalledApps = [];

    original_AllApplications.map((app, key) => {
      // console.log(app);
      if (app?.applicationModuleMetricsList?.length > 0) {
        let modulesList = [];
        app?.applicationModuleMetricsList?.map((module, moduleKey) => {
          // if(module.PodStatsList.length>0){
          modulesList.push({
            key: `${module?.displayName}_${moduleKey}_${app.model}_${key}`,
            name: `${
              module?.displayName == null ||
              module?.displayName == "" ||
              module?.displayName == undefined
                ? module?.name
                : module?.displayName
            }`,
            cpu: `${module?.cpu}`,
            memory: `${module?.memory}`,
            status: `${module?.status}`,
            version: module?.installedVersion,
          });
          // }
        });
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
          children: modulesList,
        });
      } else {
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
        });
      }
      // console.log("Corrected InstalledAPs", correctedInstalledApps);
      setRefactoredInstalledApp(correctedInstalledApps);
    });
  }, [original_AllApplications, view]);

  const columns = [
    {
      title: "Installed Application",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      // render: (name) => (
      //   <Tooltip placement="topLeft" title={name}>
      //     {name}
      //   </Tooltip>
      // ),
      render: (text, record) => {
        if (record?.isClickable) {
          return (
            <a
              onClick={() => {
                const href = `/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
                  record.originalObject
                )}&isAppInstalled=true`;

                window.location.href = `${href}`;
              }}
            >
              {text}
            </a>
          );
        } else {
          return <span>{text}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Version",
      dataIndex: "version",
      width: "20%",
      ellipsis: true,
      render: (version) => (version != null ? <span> {version}</span> : "-"),
      // responsive: ['md'],
    },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,
      width: "20%",

      render: (status) => (status == undefined ? "-" : <span>{status}</span>),
      // responsive: ['md'],
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(cpu),
                value: cpu,
              },
            ])
          : "-",
      // responsive: ['md'],
      width: "20%",
    },
    {
      title: "Memory",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(memory),
                value: memory,
              },
            ])
          : "-",
      width: "20%",
      // responsive: ['md'],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "20%",
      render: (_, record) => {
        const showUpdateIcon = isAppUpdateAvailable[record.type];
        const showDeleteIcon = record.type !== undefined;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {showDeleteIcon && (
              <Tooltip title="Uninstall Application">
                <RiDeleteBin6Fill
                  style={{
                    color: "#525252",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    showDeleteModal(record.type, edgeId, record.model)
                  }
                />
              </Tooltip>
            )}
            {showUpdateIcon && (
              // <Tooltip title="Update Availabe {record.new_version}">
              //   <TbReload
              //     style={{
              //       color: "#1f1f1f",
              //       cursor: "pointer",
              //     }}
              //     onClick={() => showUpdateModal(record.type)}
              //   />
              // </Tooltip>
              <WithTooltip>
                <IoSyncSharp
                  style={{
                    color: "#1f1f1f",
                    cursor: "pointer",
                  }}
                  onClick={() => showUpdateModal(record.type)}
                />
                <Tooltip>
                  <p>Update Available</p>
                  <p>Latest Version {record?.new_version}</p>
                </Tooltip>
              </WithTooltip>
            )}
          </div>
        );
      },
    },
  ];

  const GenerateTable = (applications) => {
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        expandable={{
          // expandedRowRender: record => (<p>{record.children[0].name}</p>),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            } else {
              return (
                <BiChevronRight
                  style={{
                    fontSize: "0px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            }
          },
        }}
        style={{
          // width: "70%",
          // flex: "1",
          // overflow:'auto'
        }}
      />
    );
  };

  const render_AddAppBtn = () => {
    return (
      <span
        onClick={() => setShowAddApplicationPannel(!showAddApplicationPannel)}
        style={{
          marginLeft: "auto",
          background: "#d1ebff",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "31px",
          padding: "5px 10px",
          // boxShadow: " 0px 4px 6px -1px #d2d2d2",
          cursor: "pointer",
          width: "215px",
        }}
      >
        <IoIosAdd style={{ fontSize: "24px" }} />{" "}
        <span>View Available Applications</span>
      </span>
    );
  };

  const adjustHeight = () => {
    let getMainContainer = document.getElementsByClassName("gridContainer");
    // let getTheAboveApp = getMainContainer[0].getElementsByClassName('App');
    // let appHeight = getTheAboveApp[0].clientHeight;

    let finalHeight = getMainContainer[0].clientHeight - 100;
    // console.log("value of inner height",getMainContainer)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  return (
    <div className="App">
      <Modal
        title="Uninstall Application"
        open={deleteModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        <p style={{ fontSize: "medium" }}>
          This is an irreversible action. Are you sure you want to uninstall the
          Application?
        </p>
        <Form onFinish={handleDeleteApplication} layout="vertical">
          <Form.Item
            label="Type 'yes' to confirm:"
            name="confirmation"
            rules={[
              // {
              //   required: true,
              //   message: "Please type 'yes' to confirm.",
              // },
              {
                validator: (_, value) => {
                  if (value.toLowerCase() === "yes") {
                    setIsDeleteButtonEnabled(true);
                    return Promise.resolve();
                  }
                  setIsDeleteButtonEnabled(false);
                  return Promise.reject("Please type 'yes' to confirm.");
                },
              },
            ]}
          >
            <Input
              autocomplete="off"
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </Form.Item>
          <Button
            style={{
              marginLeft: "55%",
              background: isDeleteButtonEnabled ? "#1f1f1f" : "#848484",
              // display: "flex",
              alignItems: "center",
              borderRadius: "30px",
              // padding: "2px 10px",
              cursor: "pointer",
              color: "white",
              fontFamily: "ABBVoice Regular",
            }}
            type="primary"
            onClick={handleDeleteOk}
            disabled={!isDeleteButtonEnabled}
            loading={load}
          >
            Unistall
          </Button>
          <Button
            style={{
              borderRadius: "30px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              fontFamily: "ABBVoice Regular",
            }}
            onClick={handleDeleteCancel}
          >
            Cancel
          </Button>
        </Form>
      </Modal>
      <Modal
        title="Update Application?"
        open={updateModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please confirm to update the application.
        </p>
        <Form onFinish={handleUpdateApplication} layout="vertical">
          <Button
            style={{
              marginLeft: "55%",
              background: "#1f1f1f",
              // display: "flex",
              alignItems: "center",
              borderRadius: "30px",
              // padding: "2px 10px",
              cursor: "pointer",
              color: "white",
              fontFamily: "ABBVoice Regular",
            }}
            type="primary"
            onClick={handleUpdateOk}
            loading={updateload}
          >
            Update
          </Button>
          <Button
            style={{
              borderRadius: "30px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              fontFamily: "ABBVoice Regular",
            }}
            onClick={handleUpdateCancel}
          >
            Cancel
          </Button>
        </Form>
      </Modal>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
      >
        <style
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Applications" />
          {!apiLoaded && <SpinnerLoader />}
        </style>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
        <div
          className="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            // height: `${
            //   isSummaryVisible ? "calc(52vh - 12vh)" : "calc(80vh - 12vh)"
            // }`,
            height: `${customHeight}px`,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              // diplay: "contents",
              width:'70%',
              backgroundColor: "#fff",
              height: "auto",
            }}
          >
            {/* {view
              ? GenerateTable(original_AllApplications)
              : GenerateTable(EdgeApplications)} */}
            {apiLoaded && original_AllApplications.length == 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  flex: 1,
                }}
              >
                <div style={{ margin: "100px 0px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Welcome to Edgenius,{" "}
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "15px" }}>
                    It's a bit lonely out here on the edge without any
                    applications installed.{" "}
                  </span>
                  <br />
                  {/* <br /> */}

                  {/* <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: "5px",
                    }}
                  > */}
                  {/* <span>Click</span> {render_AddAppBtn()} <span>to embark on your journey to a
                    powerful and personalized edge platform.</span> */}
                  {/* </span> */}
                  {/* <br /> */}
                  <span
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <span>
                      {" "}
                      Browse through the list of all available applications and
                      use
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        background: "#f3f3f3",
                        display: "flex",
                        borderRadius: "20px",
                        boxShadow: "0px 0px 0px 2px #d7d7d7",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <IoIosAdd style={{ fontSize: "20px" }} />
                      {/* Install Application */}
                    </span>
                    icon to add your favourite application to the Edge.
                  </span>
                </div>
              </div>
            )}

            {original_AllApplications.length > 0 && apiLoaded &&
              GenerateTable(refactoredInstalledApp)}
          </div>
          {_AvailableApplications.length > 0 && apiLoaded && (
          
            <div
              className="addApplicationPannel"
              style={{
                width:"30%",
                backgroundColor: "#fff",
               
              }}
            >
              <span
                      className="overFlowText availableAppSectionHeading"
                      style={{
                        width: "70%",
                        color: "#000",
                        fontSize: "18px",
                        padding: "5px 5px",
                      }}
                    >
                      Available Applications
                    </span>
                    <div style={{
                      overflow:'auto',
                      height:`${customHeight}px`
                    }}>
                    {/* <table style={{ width: "100%" }} > */}
               
               {/* <tbody> */}
                 {_AvailableApplications.length == 0 && (
                   <NoDataComponent message="No application at the moment" />
                 )}
                 {_AvailableApplications.map((app, i) => (
                
                  <div key={i} style={{borderBottom:'1px solid #f0f0f0', padding:'4px'}}>
     
                       <span
                         style={{
                           display: "flex",
                           alignItems: "center",
                           margin: "0px 4px",
                           fontSize: "15px",
                         }}
                       >
                         <span style={{width:'89%'}}>
                           <a
                             className="Hyper_link"
                             href={`/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
                               app
                             )}&isAppInstalled=false`}
                             style={{ textDecoration: "none", fontSize: "15px", background:'red' }}
                           >
                             <span className="overFlowText">
                               {app?.displayName}
                             </span>
                           </a>
                           <span className="overFlowText">{app.version}</span>
                         </span>
                         <span
                           style={{
                             marginLeft: "auto",
                             display: "flex",
                             alignItems: "center",
                             columnGap: "20px",
                           }}
                         >
                           {/* <GrNotes
                           style={{ fontSize: "20px", marginRight: "5px" }}
                         /> */}
                           {/* <IoMdSettings style={{ fontSize: "23px" }} /> */}
 
                           {app?.dummy ? (
                             <span
                               style={{
                                 cursor: "pointer",
                                 background: "#f3f3f3",
                                 display: "flex",
                                 borderRadius: "20px",
                                 boxShadow: "0px 0px 0px 2px #d7d7d7",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 paddingRight: "5px",
                               }}
                             >
                               <IoIosAdd style={{ fontSize: "20px" }} />
                               Install Application
                             </span>
                           ) : (
                             <Popover
                               id={`${app.name}`.replace(/\s/g, "")}
                               placement="left"
                               content={
                                 // sudoState / 2 == 0 ? (
                                 //   <span></span>
                                 // ) : (
                                 <div style={{ padding: "10px" }}>
                                   <AppSettings settingData={app} hide={hide} />
                                 </div>
                                 // )
                               }
                               trigger="click"
                               open={open[i]}
                               onOpenChange={handleOpenChange}
                               onClick={() => {
                                 setSudoState((prev) => prev + 1);
                                 setThisOpen(i);
                               }}
                             >
                               <span
                                 style={{
                                   cursor: "pointer",
                                   background: "#f3f3f3",
                                   display: "flex",
                                   borderRadius: "20px",
                                   boxShadow: "0px 0px 0px 2px #d7d7d7",
                                   alignItems: "center",
                                   justifyContent: "center",
                                   // paddingRight: "5px",
                                 }}
                               >
                                 <IoIosAdd style={{ fontSize: "20px" }} />
                                 {/* Install Application */}
                               </span>
                             </Popover>
                           )}
                         </span>
                       </span>
                       </div>
                                  ))}
                 {/* </tbody> */}
               {/* </table> */}
                    </div>
            
              
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllapplicationsComponents;
