
const initialState = [];

const setRouterEdgeSettings = (state= initialState, action) =>{
    switch (action.type) {
        case "SET_ROUTER_EDGE_SETTINGS":
            return state = action.payload
        default:
            return state;
    }
}

export default setRouterEdgeSettings;