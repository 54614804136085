import React, { useEffect, useState } from "react";
import Heading from "../SectionHeading/Heading";
import "./Widget.css";
// import Switch from "react-switch";
import {
  Switch,
  Checkbox,
  Button,
  Input,
  Form,
  Select,
  message,
  Popover,
} from "antd";
import { TiTick } from "react-icons/ti";
import { LiaTimesSolid } from "react-icons/lia";
import { FaSave } from "react-icons/fa";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import axios from "axios";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailableApps,
  setInstallAppsReloader,
  setInstalledApps,
  setPageNotifications,
} from "../../reduxStore/actions";
import { deploymentProgess } from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { Modal } from "antd";
import { MdOutlineDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { values } from "underscore";
import { generateTime } from "../../constants/commonFunction";
import NoDataComponent from "../NoDataComponent/NoDataComponent";

function Widget({ settingData, hide }) {
  const [form] = Form.useForm();

  const [isContainedPage, setIsContainedPage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sudoState, setSudoState] = useState(0);
  const [showAppInstallModal, setShowAppInstallModal] = useState(false);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const isAppInstalled = "true" === queryParam.get("isAppInstalled");
  const [appSystemSettings, setSystemSettings] = useState({});
  const [appAdvanceSettings, setAppAdvanceSettings] = useState({});
  const [appConfiguratorSettings, setAppConfiguratorSettings] = useState({});
  const [appModbusSettings, setAppModbusSettings] = useState({});
  const [appSCESettings, setAppSCESettings] = useState({});
  const [advanceSettingShow, setAdvancedSettingShow] = useState(false);
  const [ischanged, setIsChanged] = useState(false);
  const [customHeight, setCustomHeight] = useState(0);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [isDeletingKey, setIsDeletingKey] = useState(false);
  const [view, setView] = useState(true);
  const [view_Configuration, setView_Configuration] = useState(0);
  const [allAvailableSystems, setAllAvailableSystems] = useState([]);
  const [reloadAvailableSystem, setReloadAvailableSystem] = useState(0);
  const [isSystemKeyAdded, setIsSystemKeyAdded] = useState(false);
  let [collectedSystem, setCollectedSystem] = useState({});
  const [isFetchingAllSystem, setIsFetchingAllSystem] = useState(false);
  let [changedValues, setChangedVAlues] = useState([]);
  const [applicationObjectId, setApplicationObjectId] = useState("");
  let curr_App_reloaderValue = useSelector(
    (state) => state.setInstallAppsReloader
  );
  const [isDataPresent,setIsDataPresent] = useState(false)
    const [allSettings, setAllSettings] = useState([])
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddNotification = (notiText, icon) => {
    let tp = oldNotifications.newData;
    tp.unshift({
      notiId: generateTime(),
      icon: `${icon}`,
      text: `${notiText}`,
      time: generateTime(),
    });
    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: tp,
        },
      })
    );
  };
  const adjustHeight = () => {
    let getMainContainer = document.getElementsByClassName("gridContainer");

    let finalHeight = getMainContainer[2].clientHeight - 150;
    // console.log("value of inner height",getMainContainer)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  useEffect(() => {
    setIsFetchingAllSystem(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_SYSTEM,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setAllAvailableSystems(response.data);
        setIsFetchingAllSystem(false);
      })
      .catch((error) => {
        setIsFetchingAllSystem(false);
        console.log(error);
      });
  }, [reloadAvailableSystem]);

  // let changedValues = [];

  const collectChangeValues = (value, target, presentationType) => {
    setIsChanged(true);
    let foundIndex = changedValues.findIndex(
      (item) => item.targetPath === target
    );

    if (foundIndex == -1) {
      changedValues.push({
        value: value,
        targetPath: target,
        presentationType: presentationType,
      });
    } else {
      changedValues[foundIndex].value = value;
    }

    setSudoState((prev) => prev + 1);
    console.log(changedValues);
  };

  let selectedSettings = [];

  useEffect(() => {
    const param = window.location.pathname;
    setIsContainedPage(param !== "/appDetails"); //true on main page
    let fetchApplicationSettings;

    if(param !== "/appDetails"){
      console.log("main Page")
      fetchApplicationSettings =
      apiEndPoints.FETCH_AVAILABLE_APPLICATION_SETTINGS;
   
    }else{
      console.log("inside page")
      if(isAppInstalled){
        // console.log("1")
        fetchApplicationSettings =
        apiEndPoints.FETCH_APPLICATION_SETTINGS.replace("*edgeId*", edgeId);;
      }else{
        // console.log("2")
        fetchApplicationSettings =
        apiEndPoints.FETCH_AVAILABLE_APPLICATION_SETTINGS;
      }

    // fetchApplicationSettings = fetchApplicationSettings.replace(
    //   "*isInstalled*",
    //   isAppInstalled === null ? false : isAppInstalled
    // );
    }
   
    
    fetchApplicationSettings = fetchApplicationSettings.replace(
      "*typeId*",
      settingData.type
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchApplicationSettings,
      headers: {
        // "Content-Type": "application/json",
      },
      // data: JSON.stringify({
      //   ApplicationTypeId: settingData.type,
      //   EdgeId: edgeId,
      //   ApplicationModelId: settingData.model,
      //   IsApplicationInstalled:
      //     isAppInstalled === null ? false : isAppInstalled,
      // }),
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("App settings", response.data);
        setIsApiLoaded(true);
        if(response.data.length==0){
          setIsDataPresent(false)
          return
        }else(
          setIsDataPresent(true)
        )
        setAllSettings(response.data)
        response.data.map((sys,key)=>{
          if (sys.sectionName == "System Configuration") {
             setSystemSettings(response?.data?.[key]);
          }
        })
        // response.data.map((setting, key) => {
        //   if (setting.sectionName == "System Configuration") {
        //     setSystemSettings(response?.data?.[key]);
        //     if (response?.data?.[key]?.properties?.[0]?.value == null) {
        //     } else {
        //       setCollectedSystem(response?.data?.[key]?.properties?.[0]?.value);
        //     }
        //     selectedSettings.push(1);
        //   }

        //   if (setting.sectionName == "Advanced Settings") {
        //     setAppAdvanceSettings(response?.data?.[key]);
        //     selectedSettings.push(2);
        //   }

        //   if (setting.sectionName == "UA Configurator Settings") {
        //     setAppConfiguratorSettings(response?.data?.[key]);
        //     selectedSettings.push(3);
        //   }

        //   if (setting.sectionName == "Modbus Configurator Settings") {
        //     setAppModbusSettings(response?.data?.[key]);
        //     selectedSettings.push(4);
        //   }
        //   if (setting.sectionName == "Settings") {
        //     setAppSCESettings(response?.data?.[key]);
        //     selectedSettings.push(5);
        //   }
        // });

        // const min = Math.min(...selectedSettings);

        // setView_Configuration(min);
        setApplicationObjectId(response?.data?.[0]?.applicationObjectId);
       
      })
      .catch((error) => {
        console.log(error);
        setIsApiLoaded(true);
      });
  }, []);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const formReset = () => {
    // renderSettings();
    // console.log('clicked');
    // setSudoState(prev=>prev+1);
    form.resetFields();
    // form.setFieldsValue({ KeepAlivePeriod: 'Hello world!', CommunicationTimeout:'123' });
    changedValues = [];
  };

  const deleteInstalledSys = (key) => {
    delete collectedSystem[key];
    collectChangeValues(
      "",
      "#/controlsystemconnect/properties/associatedSystems",
      "System"
    );

    setSudoState((prev) => prev + 1);
  };

  const refactorValues = (obj) => {
    if (obj == null) return;
    return Object.keys(collectedSystem).map((key) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
            margin: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",

              flexDirection: "column",
              // gap: '5px',
            }}
            className="overFlowText"
          >
            <span style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}>
              {key}
            </span>
            <span style={{ fontFamily: "ABBVoice", fontSize: "15px" }}>
              {obj?.[key]?.value}
            </span>
          </div>
          <div>
            <MdOutlineDelete
              fontSize={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteInstalledSys(key);
              }}
            />
          </div>
        </div>
      );
    });

    //  // console.log(obj[key]) // baz
  };
  const renderInputs = (ele) => {
    switch (ele.presentationType) {
      case "Select":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
            }}
          >
            {/* <div style={{ flex: "1" }}><span style={{ fontSize: "15px" }}>{ele.name}</span></div> */}
            <Form.Item
              name={ele.name.replace(/\s/g, "")}
              // label={ele.name.replace(/\s/g, "")}
              label={ele.name}
              style={{ marginBottom: "0px" }}
              class="text_1"
            >
              <Select
                style={{
                  width: "100%",
                  height: "35px",
                  gap: "4px",
                }}
                defaultValue={ele.value}
                onChange={
                  (e) =>
                    collectChangeValues(e, ele.targets[0], ele.presentationType)
                  // console.log(e.target.value, ele.targets[0])
                }
              >
                {ele.valueDomain.map((option, key) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Option value={option} key={key}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Switch defaultChecked onChange={onSwitchChange} /> */}

            <span
              style={{ fontSize: "15px", marginBottom: "15px" }}
              class="text_1"
            >
              {ele.description}
            </span>
          </div>
        );
      case "CheckBox":
        return (
          <div
            style={{
              display: "flex",
              columnGap: "10px",
            }}
          >
            <div
              style={{
                width: "60px",
                height: "40px",
                padding: "8px, 12px, 8px, 12px",
                borderRadius: "4px",
                border: "1px",
                gap: "8px",
              }}
            >
              {/* <Checkbox
                onChange={(e) =>

                  collectChangeValues(e.target.value, ele.targets[0], ele.presentationType)
                }
              ></Checkbox> */}
              <input
                type="checkbox"
                checked={
                  ele.value == "False" ||
                  ele.value == "false" ||
                  ele.value == null ||
                  ele.value == false
                    ? false
                    : true
                }
                onChange={(e) => {
                  ele.value = e.target.checked;
                  collectChangeValues(
                    e.target.checked,
                    ele.targets[0],
                    ele.presentationType
                  );
                }}
              />
            </div>
            <div style={{ flex: "1" }} class="text_1">
              {ele.name}
            </div>
          </div>
        );
      case "Text":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <div style={{ flex: "1" }}><span style={{ fontSize: "15px" }}>{ele.name}</span></div> */}
            {/* <div style={{ width: "100%"}}> */}
            <Form.Item
              name={ele.name.replace(/\s/g, "")}
              // label={ele.name.replace(/\s/g, "")}
              label={ele.name}
              class="text_1"
              style={{ marginBottom: "0px" }}
            >
              <Input
                defaultValue={ele.value}
                onChange={(e) =>
                  collectChangeValues(
                    e.target.value,
                    ele.targets[0],
                    ele.presentationType
                  )
                }
              />
            </Form.Item>
            <span
              style={{ fontSize: "15px", marginBottom: "15px" }}
              class="text_1"
            >
              {ele.description}
            </span>
            {/* </div> */}
          </div>
        );
      case "System":
        return <>{refactorValues(ele.value)}</>;
      default:
        break;
    }
  };

  const renderSettings = (settingArray) => {
    return settingArray?.properties?.map((ele, key) => (
      <div key={key}>{renderInputs(ele)}</div>
    ));
  };

  let AllInstalledApp = useSelector((state) => state.setInstalledApps);
  let AvailableApplications = useSelector((state) => state.setAvailableApps);
  const dispatch = useDispatch();

  // const AvailableApplications = useSelector((state) => state.setAvailableApps);
  let popoverId = useSelector((state) => state.deploymentProgess);

  useEffect(() => {
    if (isContainedPage) {
      if (popoverId === settingData.name) {
        let asdf = popoverId.replace(/\s/g, "");
        const ele = document.getElementById(asdf);
        ele.setAttribute("style", "display:none;");
      }
    }
  }, [popoverId]);

  const handleApply = () => {
    hide();
    setShowAppInstallModal(true);
  };

  const handleApplyy = () => {
    setIsSaving(true);
    if (isAppInstalled == true) {
      let installed_data = JSON.stringify({
        ApplicationTypeId: settingData.type,
        EdgeId: edgeId,
        ApplicationModelId: settingData.model,
        isApplicationInstalled: isAppInstalled,
        applicationObjectId: applicationObjectId,
        applicationSettingInstallationConfig: changedValues,
        systemConfig: collectedSystem,
      });
      console.log("updating the app", JSON.parse(installed_data));
      let installed_config = {
        method: "put",
        maxBodyLength: Infinity,
        url: apiEndPoints.UPDATE_INSTALLED_APP,
        headers: {
          "Content-Type": "application/json",
        },
        data: installed_data,
      };


      axios
        .request(installed_config)
        .then((response) => {
          setIsSaving(false);
          setShowAppInstallModal(false);
          message.success("Application settings applied successfully");
          AddNotification("Application settings applied successfully", "good");
        })
        .catch((error) => {
          setIsSaving(false);
          console.log(error);
          message.error("Application settings failed");
          AddNotification("Application settings failed", "failed");
        });
    } else {
      let data = JSON.stringify({
        ApplicationTypeId: settingData.type,
        EdgeId: edgeId,
        ApplicationModelId: settingData.model,
        applicationSettingInstallationConfig: changedValues,
        systemConfig: collectedSystem,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: apiEndPoints.INSTALL_APPLICATION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      // console.log(JSON.parse(data))
      axios
        .request(config)
        .then((response) => {
          setIsSaving(false);
          if (isContainedPage) {
            dispatch(deploymentProgess({ payload: settingData.name }));
            // setTimeout(() => {
            //   let updatedArray = AllInstalledApp;
            //   let toPushApplication = {
            //     isInstalled: true,
            //     IsUpdateAvailable: false,
            //     installedVersion: null,
            //     model: settingData.model,
            //     type: settingData.type,
            //     name: settingData.name,
            //     version: settingData.version,
            //     platformType: settingData.platformType,
            //     isClickable: true,
            //     children: [],
            //   };
            //   updatedArray.push(toPushApplication);
            //   // console.log(updatedArray);
            //   dispatch(setInstalledApps({ payload: updatedArray }));

            //   let _AvailableApps = AvailableApplications.filter(
            //     (app) => app.name != settingData.name
            //   );
            //   // console.log(_AvailableApps);

            //   dispatch(setAvailableApps({ payload: _AvailableApps }));

            //   // const nodeList = document.querySelectorAll(".ant-popover-inner");
            //   // for (let i = 0; i < nodeList.length; i++) {
            //   //   nodeList[i].style.display = "block";
            //   // }
            // }, 500);

            dispatch(
              setInstallAppsReloader({ payload: curr_App_reloaderValue + 1 })
            );
          }
          message.success("Application installed successfully");
          AddNotification("Application installed successfully", "good");
        })
        .catch((error) => {
          setIsSaving(false);
          console.log(error);
          message.error("Application installation failed");
          AddNotification("Application installation failed", "failed");
        });
    }
  };

  const installKey = (values) => {
    if (values.selectedSystem == undefined) return;

    let actualVal = JSON.parse(values.selectedSystem);
    Object.assign(collectedSystem, {
      [actualVal.id]: { value: actualVal.name },
    });

    setSudoState((prev) => prev + 1);
    collectChangeValues(
      "",
      "#/controlsystemconnect/properties/associatedSystems",
      "System"
    );
    console.log("Collected Systems", collectedSystem);
    // console.log("SystemSEttings", appSystemSettings.properties[0].value);
  };

  const addtheSystem = (values) => {
    if (values.keyname == undefined) return;
    setIsSystemKeyAdded(true);

    let data = JSON.stringify({
      name: values.keyname,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.ADD_NEW_SYSTEM,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setIsSystemKeyAdded(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSystemKeyAdded(false);
      });
  };

  const popoverToKeyAdd = () => {
    return (
      <>
        {" "}
        <div
          className="screenTabs"
          style={{
            marginTop: "0px",
            marginBottom: "20px",
            justifyContent: "space-around",
          }}
        >
          <span
            className={
              view == true
                ? "viewOptions active heading_2"
                : "viewOptions heading_2"
            }
            onClick={() => setView(true)}
          >
            New Key
          </span>

          <span
            className={
              view == false
                ? "viewOptions active heading_2"
                : "viewOptions heading_2"
            }
            onClick={() => {
              setView(false);
              setReloadAvailableSystem((prev) => prev + 1);
            }}
          >
            Available keys
          </span>
        </div>
        {view && (
          <Form
            layout="vertical"
            style={{
              width: 250,
            }}
            onFinish={addtheSystem}
          >
            <Form.Item label="Name" required name="keyname" class="text_1">
              <Input />
            </Form.Item>

            <Form.Item style={{ marginBottom: "10px" }}>
              <div
                className="button-options"
                style={{
                  justifyContent: "end",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  type="primary"
                  // className="button-option"
                  htmlType="submit"
                  loading={isSystemKeyAdded}
                  // text="Register Identity Provider"
                  style={{
                    background: "black",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    border: "0px",
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Add Key
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {!view && (
          <Form
            layout="vertical"
            style={{
              width: 250,
            }}
            onFinish={installKey}
          >
            <Form.Item label="Keys" name="selectedSystem">
              <Select>
                {allAvailableSystems.map((sys) => {
                  return (
                    <Select.Option value={JSON.stringify(sys)}>
                      {sys.name}
                    </Select.Option>
                  );
                })}
              </Select>
              {isFetchingAllSystem && (
                <div
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "7px",
                  }}
                >
                  <SpinnerLoader />
                </div>
              )}
            </Form.Item>

            <Form.Item style={{ marginBottom: "10px" }}>
              <div
                className="button-options"
                style={{
                  justifyContent: "end",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  type="primary"
                  // className="button-option"
                  htmlType="submit"
                  // loading={isSavingIdentityProvider}
                  // text="Register Identity Provider"
                  style={{
                    background: "black",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    border: "0px",
                    color: "white",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Get Key
                </Button>

                <Button
                  type="primary"
                  // className="button-option"
                  htmlType="submit"
                  loading={isDeletingKey}
                  // text="Register Identity Provider"
                  style={{
                    border: "1px solid #e12e2e",
                    alignItems: "center",
                    borderRadius: "31px",
                    padding: "5px 10px",
                    boxShadow: "unset",
                    cursor: "pointer",
                    fontWeight: "500",
                    color: "#e12e2e",
                    backgroundColor: "unset",
                    fontSize: "15px",
                    fontFamily: "ABBVoice",
                  }}
                >
                  Delete Key
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </>
    );
  };
  const AppSettingsContainer = () => {
    return (
      appSystemSettings?.properties?.length > 0 && (
        <>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            {" "}
            {/* <Form
              // {...layout}
              layout="vertical"
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              style={{ maxWidth: 600 }}
            > */}
            <div>
              <span style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}>
                Connection Keys
              </span>
              <br />
              <span
                style={{
                  fontFamily: "ABBVoice",
                  width: isContainedPage ? "560px" : "auto",
                  display: "block",
                  fontSize: "15px",
                }}
              >
                In order to establish a connection between your control system
                and your edge get and then copy the unique connection key listed
                below corresponding to your system to your control System
                configuration.
              </span>
            </div>
            {renderSettings(appSystemSettings)}
            {/* <a style={{display:'flex',alignItems:'center', marginLeft:'-5px'}}> <IoIosAdd fontSize={20}/> Get Key</a> */}
            <Popover
              id="popoverBtn"
              placement="right"
              content={
                <div style={{ padding: "10px" }}>{popoverToKeyAdd()}</div>
              }
              trigger="click"
            >
              <span
                className="Hyper_link"
                style={{
                  cursor: "pointer",
                  width: "fit-content",
                  display: "flex",
                  marginLeft: "-5px",
                  alignItems: "center",
                  fontSize: "15px",
                }}
              >
                <IoIosAdd style={{ fontSize: "20px" }} />
                Get Key
              </span>
            </Popover>
            {/* </Form> */}
          </div>
        </>
      )
    );
  };

  // const AppAdvanceSettingsContainer = () => {
  //   return (
  //     appAdvanceSettings?.properties?.length > 0 && (
  //       <div style={{ marginTop: "10px" }}>
  //         {/* <a
  //           onClick={() => {
  //             setAdvancedSettingShow(!advanceSettingShow);
  //           }}
  //         >
  //           {" "}
  //           {advanceSettingShow ? "Hide" : "Show"} Advance settings
  //         </a> */}
  //         {/* {advanceSettingShow && ( */}
  //         <div style={{ marginTop: "10px" }}>
  //           <Form layout="vertical">{renderSettings(appAdvanceSettings)}</Form>
  //         </div>
  //         {/* // )} */}
  //       </div>
  //     )
  //   );
  // };

  // const AppConfiguratorSettingsContainer = () => {
  //   return (
  //     appConfiguratorSettings?.properties?.length > 0 && (
  //       <div style={{ marginTop: "10px" }}>
  //         {/* <a
  //           onClick={() => {
  //             setAdvancedSettingShow(!advanceSettingShow);
  //           }}
  //         >
  //           {" "}
  //           {advanceSettingShow ? "Hide" : "Show"} Advance settings
  //         </a> */}
  //         {/* {advanceSettingShow && ( */}
  //         <div style={{ marginTop: "10px" }}>
  //           <Form
  //             // labelCol={{
  //             //   span: 4,
  //             // }}
  //             // wrapperCol={{
  //             //   span: 14,
  //             // }}
  //             layout="vertical"
  //             // initialValues={{
  //             //   size: componentSize,
  //             // }}
  //             // onValuesChange={onFormLayoutChange}
  //             // size={componentSize}
  //             style={
  //               {
  //                 // width: 250,
  //               }
  //             }
  //           >
  //             {renderSettings(appConfiguratorSettings)}
  //           </Form>
  //         </div>
  //         {/* // )} */}
  //       </div>
  //     )
  //   );
  // };

  // const AppModbusContainer = () => {
  //   // console.log(appModbusSettings);
  //   return (
  //     appModbusSettings?.properties?.length > 0 && (
  //       <div style={{ marginTop: "10px" }}>
  //         {/* <a
  //           onClick={() => {
  //             setAdvancedSettingShow(!advanceSettingShow);
  //           }}
  //         >
  //           {" "}
  //           {advanceSettingShow ? "Hide" : "Show"} Advance settings
  //         </a> */}
  //         {/* {advanceSettingShow && ( */}
  //         <div style={{ marginTop: "10px" }}>
  //           <Form
  //             // labelCol={{
  //             //   span: 4,
  //             // }}
  //             // wrapperCol={{
  //             //   span: 14,
  //             // }}
  //             layout="vertical"
  //             // initialValues={{
  //             //   size: componentSize,
  //             // }}
  //             // onValuesChange={onFormLayoutChange}
  //             // size={componentSize}
  //             style={
  //               {
  //                 // width: 250,
  //               }
  //             }
  //           >
  //             {renderSettings(appModbusSettings)}
  //           </Form>
  //         </div>
  //         {/* // )} */}
  //       </div>
  //     )
  //   );
  // };

  // const AppSCESettingsContainer = () => {
  //   return (
  //     appSCESettings?.properties?.length > 0 && (
  //       <div style={{ marginTop: "10px" }}>
  //         {/* <a
  //           onClick={() => {
  //             setAdvancedSettingShow(!advanceSettingShow);
  //           }}
  //         >
  //           {" "}
  //           {advanceSettingShow ? "Hide" : "Show"} Advance settings
  //         </a> */}
  //         {/* {advanceSettingShow && ( */}
  //         <div style={{ marginTop: "10px" }}>
  //           <Form
  //             // labelCol={{
  //             //   span: 4,
  //             // }}
  //             // wrapperCol={{
  //             //   span: 14,
  //             // }}
  //             layout="vertical"
  //             // initialValues={{
  //             //   size: componentSize,
  //             // }}
  //             // onValuesChange={onFormLayoutChange}
  //             // size={componentSize}
  //             style={
  //               {
  //                 // width: 250,
  //               }
  //             }
  //           >
  //             {renderSettings(appSCESettings)}
  //           </Form>
  //         </div>
  //         {/* // )} */}
  //       </div>
  //     )
  //   );
  // };

  const paintScreenTabs = () =>{
    return allSettings.map((appSettings,key)=>{
      return  <span
      className={
        view_Configuration == key
          ? "viewOptions active"
          : "viewOptions"
      }
      onClick={() => {
        setView_Configuration(key);
        setApplicationObjectId(appSettings?.applicationObjectId);
      }}
      style={{ padding: 0 }}
    >
      {`${appSettings.sectionName}`}
    </span>
    })
  }

  const paintSettings = () =>{
    
   return allSettings.map((appSettings,key)=>{
      return <div style={view_Configuration==key?{display:'block'}:{display:'none'}}>
        {appSettings?.properties?.length > 0 ? (
        <div style={{ marginTop: "10px"}}>
          <div style={{ marginTop: "10px" }}>
            <Form
             layout="vertical"
             style={
                {
                  // width: 250,
                }
              }
            >
              {appSettings?.sectionName=="System Configuration"?AppSettingsContainer():renderSettings(appSettings)}
            </Form>
          
          </div>
        </div>
      ):(<NoDataComponent message={`No settings at the moment`} />)}
      </div>
    })
  }

  return (
    <div className="App" style={{ padding: 0 }}>
      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {window.location.pathname.includes("appDetails") ? (
          <div
            className="Heading_container"
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              width: "98%",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            <Heading text="Application Settings" />{" "}
            {!isApiLoaded && <SpinnerLoader />}
          </div>
        ) : (
          <div style={{ width: "590px" }}>
            <Heading text={settingData?.name} />{" "}
            {!isApiLoaded && <SpinnerLoader />}
          </div>
        )}
      </span>
      <div style={{ marginTop: "10px" }}></div>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: "10px",
          height: `${isContainedPage ? "42vh" : `${customHeight}px`}`,
          fontFamily: "ABBVoice",
          padding: "10px",
        }}
      >
        {isApiLoaded && (
          <>
            {isContainedPage && (
              <span style={{ fontSize: "18px" }}>
                Please set and confirm below settings for application deployment
              </span>
            )}
  {!isDataPresent && <NoDataComponent />}
            <div
              className="screenTabs"
              style={{
                marginTop: isContainedPage ? "20px" : "10px",
                marginBottom: "30px",
              }}
            >
               {/* {appSystemSettings?.properties?.length > 0 && (
                <span
                  className={
                    view_Configuration == 1
                      ? "viewOptions active"
                      : "viewOptions"
                  }
                  onClick={() => {
                    setView_Configuration(1);
                    setApplicationObjectId(
                      appSystemSettings?.applicationObjectId
                    );
                  }}
                  style={{ padding: 0 }}
                >
                  System Configuration
                </span>
              )} */}

              {/* {appAdvanceSettings?.properties?.length > 0 && (
                <span
                  className={
                    view_Configuration == 2
                      ? "viewOptions active"
                      : "viewOptions"
                  }
                  onClick={() => {
                    setView_Configuration(2);
                    setApplicationObjectId(
                      appAdvanceSettings?.applicationObjectId
                    );
                  }}
                  style={{ paddingLeft: "20px" }}
                >
                  Advanced Settings
                </span>
              )}
              {appConfiguratorSettings?.properties?.length > 0 && (
                <span
                  className={
                    view_Configuration == 3
                      ? "viewOptions active"
                      : "viewOptions"
                  }
                  onClick={() => {
                    setView_Configuration(3);
                    setApplicationObjectId(
                      appConfiguratorSettings?.applicationObjectId
                    );
                  }}
                  style={{ padding: 0 }}
                >
                  UA Configurator Settings
                </span>
              )}

              {appModbusSettings?.properties?.length > 0 && (
                <span
                  className={
                    view_Configuration == 4
                      ? "viewOptions active"
                      : "viewOptions"
                  }
                  onClick={() => {
                    setView_Configuration(4);
                    setApplicationObjectId(
                      appModbusSettings?.applicationObjectId
                    );
                  }}
                  style={{ padding: 0 }}
                >
                  Modbus Configurator Settings
                </span>
              )}

              {appSCESettings?.properties?.length > 0 && (
                <span
                  className={
                    view_Configuration == 5
                      ? "viewOptions active"
                      : "viewOptions"
                  }
                  onClick={() => {
                    setView_Configuration(5);
                    setApplicationObjectId(appSCESettings?.applicationObjectId);
                  }}
                  style={{ padding: 0 }}
                >
                  Settings
                </span>
              )} */}

              {paintScreenTabs()}
            </div>
          </>
        )}

        {/* {view_Configuration == 1 && AppSettingsContainer()}
        {view_Configuration == 2 && AppAdvanceSettingsContainer()}
        {view_Configuration == 3 && AppConfiguratorSettingsContainer()}
        {view_Configuration == 4 && AppModbusContainer()}
        {view_Configuration == 5 && AppSCESettingsContainer()} */}


        {paintSettings()}
      </div>
      {isApiLoaded && isDataPresent && (
        <>
          <div
            className="button-group"
            style={{
              display: "flex",
              columnGap: "20px",
              marginTop: "30px",
              justifyContent: "flex-end",
              padding: "0px 10px",
            }}
          >
            <Button
              style={{
                background: "black",
                display: "flex",
                alignItems: "center",
                borderRadius: "31px",
                padding: "5px 10px",
                // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                cursor: "pointer",
                fontWeight: "500",
                border: "0px",
                color: "white",
                fontSize: "15px",
                fontFamily: "ABBVoice",
              }}
              onClick={handleApplyy}
              loading={isSaving}
              // disabled={!ischanged ? true : false}
            >
              {isAppInstalled ? "Update" : "Save Configuration and Install"}
            </Button>
            {/* <Button
              // sizeClass="medium"
              style={{
                color: "#000",
                // background:'#000',
                borderRadius: "100px",
                fontWeight: "500",
              }}
              variant="outline"
              onClick={formReset}
            >
              Reset
            </Button> */}
            <Button
              // sizeClass="medium"
              style={{
                color: "#000",
                // background:'#000',
                borderRadius: "100px",
                fontWeight: "500",
                fontSize: "15px",
                fontFamily: "ABBVoice",
              }}
              variant="outline"
              onClick={hide}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default Widget;
