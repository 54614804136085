import React, { useDebugValue, useEffect } from 'react'
import Widget from './Widget'

function AppSettings({settingData, hide}) {

  return (
    <Widget settingData={settingData} hide={hide}/>
  )
}

export default AppSettings