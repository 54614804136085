import Heading from "../SectionHeading/Heading";

import React, { useState } from "react";

import ReactMarkdown from "react-markdown";
import "./Widget.css";
import { Button, Modal } from "antd";

const AppReleaseNote = (props) => {
  if (props.releaseNote) {
    let formattedDate = atob(props.releaseNote.releaseDate || "");
    formattedDate = formattedDate.replace(/T/g, " ");
    formattedDate = formattedDate.replace(/[+].*/g, "");
    return (
      <>
        <div className="release-notes-header-wrap">
          <div className="release-note-version">{`VERSION  ${props.releaseNote?.version}`}</div>
          <div className="release-note-time-stamp">{`${formattedDate}`}</div>
        </div>
        <div className="releasenote-text">
          <ReactMarkdown
            children={atob(props.releaseNote?.releaseNotes || "")}
          />
        </div>
        <div className="releasenote-known-issues">
          <p>Known Issues</p>
          <ReactMarkdown
            children={atob(props.releaseNote?.releaseKnownIssues || "")}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

function Widget({ props }) {
  console.log(props);

  const [showReleaseModal, setShowReleaseModal] = useState(false);
  return (
    <section className="App" style={{padding:0}}>
      <div className="Heading_container" style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              width: "96%%",
              borderBottom: "1px solid #f0f0f0",
            }}>
      <Heading text="Release Notes" />
      </div>

      <div className="column releasenote-container">
        <div className="releasenote-content" style={{marginTop: "40px"}}>
          {/* {showHistory ? ( */}
          <Modal
            centered
            open={showReleaseModal}
            onOk={() => setShowReleaseModal(false)}
            onCancel={() => setShowReleaseModal(false)}
            width="80%"
          >
            <div className="Heading_container" style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              width: "90%",
              // marginTop: "-43px"
            }}>
      <Heading text="Release Notes" />
      </div>
            <hr style={{}}/>
            {props.releaseHistory.map((releasenote) => (
              <>
                <AppReleaseNote releaseNote={releasenote} />
              </>
            ))}
          </Modal>

          {/* ) : ( */}
          <AppReleaseNote
            releaseNote={
              props?.releaseHistory?.length > 0
                ? props.releaseHistory?.[0]
                : undefined
            }
          />
          {/* )} */}
        </div>
        <div className="releasenote-footer">
          <Button
            style={{ 
              background: "#000",
              display: "flex",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 10px",
              // boxShadow: " 0px 4px 6px -1px #d2d2d2",
              cursor: "pointer",
              fontWeight: "500",
              border:'0px',
              color:'#fff',
              marginLeft:'auto',
              fontFamily:'ABBVoice',
              fontSize:'15px'
            }}
            // type="primary"
            // sizeClass="small"
            // text={showHistory ? "Hide history" : "Show history"}
            onClick={() => {
              setShowReleaseModal(true);
            }}
          >
            Show more
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Widget;
