import React, { useEffect,useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import ApplicationSummary from "../components/ApplicationSummary/ApplicationSummary";
import { Grid, Box } from "grommet/components";

import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch } from "react-redux";
import { setAvailableApps, setInstalledApps } from "../reduxStore/actions";
import { useParams } from "react-router-dom";
import AlltenantappComponents from "./pageComponents/AlltenantappComponents";
import { Col, Row } from "antd";

function AllTenantapplications() {
  const dispatch = useDispatch();
  // const access_token = localStorage.getItem("access_token");

  // const { edgeId } = useParams();
  const queryParam = new URLSearchParams(window.location.search)
  const edgeId = queryParam.get("edgeId")
  // const edgeId = "b926a670-f55e-44a9-9e21-539e4a531bcc";
  const [dataPresent, setDataPresent] = useState(false)

  const _original_AllApplications = [
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_1",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_3",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "TestAPP_3",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
    {
      model: "abb.pa.edgenius.application",
      type: "abb.pa.edgenius.application.AssetHub.1",
      name: "ABB FA Edgenius Application Asset Hub.1",
      version: "1.0.0",
      platformType: "connectondemand",
      status: "Running",
      edgeMetrics: [
        { metric: "CPU_USAGE", data: [{ v: "12.951883374057369" }] },
        { metric: "RAM_USED", data: [{ v: "97.91404235811491" }] },
        { metric: "DISK_USED", data: [{ v: "60.74719000125062" }] },
      ],
    },
  ];

 


  // useEffect(() => {
  //   //List of Available Applications
  //   let toGetAvailableApps = apiEndPoints.FETCH_AVAILABLE_APPS.replace(
  //     "*edgeId*",
  //     edgeId
  //   );

  //   let Fetch_Availableapps = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: toGetAvailableApps,
  //     headers: {
  //       // Authorization: `Bearer ${access_token}`,
  //     },
  //   };
  //   axios
  //     .request(Fetch_Availableapps)
  //     .then((response) => {
  //       console.log(response.data);
  //       dispatch(setAvailableApps({ payload: response.data }));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   //List of Installed Applications
  //   let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
  //     "*edgeId*",
  //     edgeId
  //   );

  //   let Fetch_Installedapps = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: toGetInstalledApps,
  //     headers: {
  //       // Authorization: `Bearer ${access_token}`,
  //     },
  //   };
  //   axios
  //     .request(Fetch_Installedapps)
  //     .then((response) => {

  //       dispatch(setInstalledApps({ payload: response.data }));
  //       // dispatch(setInstalledApps({payload:_original_AllApplications}))\

  //       if(response.data.length>0){
  //         setDataPresent(true)

  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        {/* <Grid
          rows={["fit-content", "49%"]}
          columns={["94vw"]}
          gap="small"
          ////// [a,b] are the placements cell where a=> Going_Side and b=> going_Down
          areas={[
            { name: "applicationSummary", start: [0, 0], end: [0, 1] },
            { name: "alledgesComponent", start: [0, 1], end: [0, 1] },
          ]}
        >
    

          <Box
            className="gridContainer"
            style={{ height: "88vh" }}
            gridArea="alledgesComponent"
          >
            <AlltenantappComponents loadingData={dataPresent}/>
          </Box>
        </Grid> */}

        <Row style={{ gridGap: "10px", paddingRight:'20px' }}>
        <Col flex="1 1 300px" style={{ display:'grid', Gap: "10px",  height: '90vh'}}  className="gridContainer">
        <AlltenantappComponents loadingData={dataPresent}/>
        </Col>
      </Row>

      </div>
    </section>
  );
}

export default AllTenantapplications;
